<template>
  <SetRelatedTasksForm
    :proceeding-id="proceedingId"
    :entity-id="documentId"
    :query="query"
    :query-variables="() => ({ documentId })"
    :query-update="(data) => data.list.items[0]"
    :mutation="mutation"
    :mutation-error-mapper="({ data: { document } }) => (document.hasOwnProperty('errors') ? document.errors : [])"
    :mutation-variables="
      (data) => {
        data.documentId = documentId;
        return { input: data };
      }
    "
    @done="$emit('done')"
    @close="close"
  />
</template>

<script>
import GET_ONE_DOCUMENT from '@/graphql/document/query/GetOneDocumentQuery.gql';
import SET_RELATED_TASKS_MUTATION from '@/graphql/document/mutation/SetDocumentRelatedTasks.gql';
import SetRelatedTasksForm from '@/components/Common/Form/SetRelatedTasksForm.vue';

export default {
  name: 'SetDocumentRelatedTasks',
  components: { SetRelatedTasksForm },
  data() {
    return {
      mutation: SET_RELATED_TASKS_MUTATION,
      query: GET_ONE_DOCUMENT,
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    documentId: { type: String, required: true },
  },
  methods: {
    close(proceeding) {
      if (proceeding) {
        this.$router.push({ name: 'proceedings/details', params: { id: proceeding.id } });
        return;
      }
      this.$router.push({ name: 'proceedings' });
    },
  },
};
</script>
