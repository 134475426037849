<template>
  <v-container class="lighten-5 mb-6">
    <v-btn bottom color="pink" dark fab fixed- right @click="$router.push({ name: 'document-templates' })">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-row gutters>
      <v-col>
        <GraphqlServerSideDataGrid
          enable-search
          :forced-filters="[
            {
              field: 'type',
              value: 'writing',
              type: 'EQ',
            },
          ]"
          title="Pisma"
          :headers="headers"
          :query="query"
          show-select
          v-model="selected"
        >
          <template v-slot:[`top`]>
            <v-btn @click="downloadPdf" :disabled="selected.length < 1" small class="ml-5 mb-2">Pobierz pdf</v-btn>
            <v-btn @click="print" :disabled="selected.length < 1" small class="ml-2 mb-2">Drukuj</v-btn>
          </template>

          <template v-slot:[`item.kind`]="{ item }">
            <v-chip outlined small :color="item.kind | documentKindClass">
              {{ item.kind | documentKindName }}
            </v-chip>
          </template>

          <template v-slot:[`item.cardNumbers`]="{ item }">
            {{ item.cardNumbers.from | orEmpty }} - {{ item.cardNumbers.to | orEmpty }}
          </template>

          <template v-slot:[`item.proceeding`]="{ item }">
            {{ item.proceeding.signature }}
            {{ item.proceeding.debtors.map((debtor) => `${debtor.name} ${debtor.surname}`).join(', ') }}
          </template>

          <template v-slot:[`item.advisor`]="{ item }">
            <AdvisorChip small :advisor="item.proceeding.advisor" />
          </template>

          <template v-slot:[`item.name`]="{ item }"> {{ item.name }} </template>

          <template v-slot:[`item.senderReceiver`]="{ item }">
            {{ item.kind | getSenderOrReceiverPrefix }}
            <span v-if="item.recipient">{{ item.recipient.name }}</span>
            <span v-else-if="item.recipientSender">{{ item.recipientSender.name | orEmpty }}</span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <DocumentShipmentStatus :document="item" />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <DocumentPreviewModal :document-id="item.id" :max-embed-levels="3">
              <template v-slot:activator="{ openDialog }">
                <v-btn x-small outlined class="mr-1" @click="openDialog">pokaż</v-btn>
              </template>
            </DocumentPreviewModal>
            <v-btn
              v-if="item.acl_edit"
              @click="$router.push({ name: 'documents/edit', params: { id: item.id } })"
              outlined
              x-small
              >edytuj
            </v-btn>
          </template>
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSenderOrReceiver, getSenderOrReceiverPrefix } from '@/helpers/documents';
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import DocumentShipmentStatus from '@/components/Proceeding/Tabs/DocumentShipmentStatus.vue';
import DOCUMENT_LIST_QUERY from '@/graphql/document/query/DocumentListQuery.gql';
import DocumentPreviewModal from '@/components/Document/DocumentPreviewModal.vue';
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import FilterBySelectField from '@/components/Filters/FilterBySelectField.vue';
import FilterByAdvisor from '@/components/Filters/FilterByAdvisor.vue';
import { mergeFiles, mergeFilesAndPrint } from '@/helpers/pdf/DocumentsMerger';

export default {
  name: 'DocumentsListPage',
  components: { DocumentPreviewModal, AdvisorChip, DocumentShipmentStatus, GraphqlServerSideDataGrid },
  filters: {
    getSenderOrReceiver,
    getSenderOrReceiverPrefix,
  },
  data() {
    return {
      query: DOCUMENT_LIST_QUERY,
      selected: [],
      headers: [
        {
          text: 'Rodzaj',
          sortable: true,
          value: 'kind',
          graphqlFilter: {
            component: FilterBySelectField,
            props: {
              items: [
                { text: 'Wychodząca - sąd', value: 'OUTGOING_COURT' },
                { text: 'Wychodząca - dłużnik', value: 'OUTGOING_CREDITOR' },
                { text: 'Wychodząca - inne', value: 'OUTGOING_OTHER' },
              ],
            },
          },
        },
        { text: 'Nr kart', value: 'cardNumbers' },
        { text: 'Nazwa', value: 'name', sortable: false },
        { text: 'Postępowanie', value: 'proceeding', sortable: false },
        {
          text: 'Doradca',
          value: 'advisor',
          sortable: false,
          graphqlFilter: { component: FilterByAdvisor },
        },
        { text: 'Powiązania', value: 'relations', sortable: false },
        { text: 'Nadawca/Odbiorca', value: 'senderReceiver', sortable: false },
        { text: 'Status', value: 'state', sortable: false },
        { text: 'Akcje', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    downloadPdf() {
      const files = this.selected
        .map((document) => document.file)
        .filter((file) => file !== null && file !== undefined)
        .map((file) => file.downloadUrl);
      mergeFiles(files).catch((e) => {
        // eslint-disable-next-line no-alert
        alert(`Wystąpił błąd: ${e.message}`);
      });
    },
    print() {
      const files = this.selected
        .map((document) => document.file)
        .filter((file) => file !== null && file !== undefined)
        .map((file) => file.downloadUrl);
      mergeFilesAndPrint(files);
    },
  },
};
</script>
