<template>
  <v-menu style="background: white" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on" v-bind:buttonProps="buttonProps">
        <v-btn v-bind="buttonProps" color="primary" v-on="on">dane kontaktowe</v-btn>
      </slot>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content v-if="entity && entity.contactDetails">
          <MarkdownParser :value="entity.contactDetails" />
        </v-list-item-content>
        <v-list-item-title v-else>brak danych</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import MarkdownParser from '@/components/Common/MarkdownParser.vue';

export default {
  name: 'ContactDetailsWidgetHoover',
  components: { MarkdownParser },
  props: {
    entity: {
      type: Object,
      required: false,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: () => ({
        small: true,
        outlined: true,
      }),
    },
  },
};
</script>
