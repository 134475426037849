<template>
  <v-card color="yellow" class="lighten-3">
    <div>
      <v-toolbar dark color="yellow" class="darken-2">
        <v-toolbar-title>
          <strong> {{ note.title }} </strong>
        </v-toolbar-title>
      </v-toolbar>
      <div class="pa-5">
        <p>
          Sporządzona dnia <strong>{{ note.createdAt | date }}</strong>
        </p>
        <p>Dodał <AdvisorChip class="ml-0" small :advisor="note.createdBy"></AdvisorChip></p>
        <v-divider class="mb-4 mt-1"></v-divider>
        <div>
          <p>{{ note.content }}</p>
        </div>
        <v-divider class="mb-5 mt-3" v-if="note.relatedTasks.length > 0 || note.relatedDocuments.length > 0" />
        <div v-if="note.relatedTasks.length > 0">
          Powiązane zadania:
          <p></p>
          <RelatedTasksChip x-small :tasks="note.relatedTasks" />
        </div>
        <div v-if="note.relatedDocuments.length > 0" class="mt-5">
          Powiązane dokumenty:
          <RelatedDocumentsChip :max-embed-levels="3" :documents="note.relatedDocuments" x-small class="pt-4" />
        </div>

        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="noteId = note.id" color="primary" fab x-small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edytuj notatkę</span>
          </v-tooltip>

          <EditProceedingNoteForm :proceedingId="proceedingId" v-if="noteId" @close="noteId = null" :noteId="noteId" />
          <TaskDetailsModal @close="selectedTaskId = null" :task-id="selectedTaskId" v-if="selectedTaskId" />
          <SetNoteRelatedTasksForm :proceedingId="proceedingId" :noteId="note.id" class="ml-2" @done="updateMasonry" />
          <SetNoteRelatedDocumentsForm
            :proceeding-id="proceedingId"
            :noteId="note.id"
            class="ml-2"
            @done="updateMasonry"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import RelatedDocumentsChip from '@/components/Common/RelatedDocumentsChip.vue';
import RelatedTasksChip from '@/components/Common/RelatedTasksChip.vue';
import SetNoteRelatedDocumentsForm from '@/components/Proceeding/Form/SetNoteRelatedDocumentsForm.vue';
import SetNoteRelatedTasksForm from '@/components/Proceeding/Form/SetNoteRelatedTasksForm.vue';
import TaskDetailsModal from '@/components/Task/TaskDetailsModal.vue';

import EditProceedingNoteForm from '@/components/Proceeding/Form/EditProceedingNoteForm.vue';

export default {
  name: 'NoteCard',
  components: {
    AdvisorChip,
    RelatedTasksChip,
    RelatedDocumentsChip,
    SetNoteRelatedTasksForm,
    SetNoteRelatedDocumentsForm,
    TaskDetailsModal,
    EditProceedingNoteForm,
  },
  data() {
    return {
      selectedTaskId: null,
      noteId: null,
    };
  },
  props: {
    note: { type: Object, required: true },
    proceedingId: { type: String, required: false },
  },
  methods: {
    updateMasonry() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry();
      }
    },
  },
};
</script>

<style scoped></style>
