<template>
  <div class="mt-3">
    <RelatedNoteModal :note="note" :proceeding-id="proceedingId">
      <template v-slot:activator="{ openDialog }">
        <v-btn v-bind="$props" @click="openDialog">{{ note.title }}</v-btn>
      </template>
    </RelatedNoteModal>
  </div>
</template>

<script>
import RelatedNoteModal from '@/components/Task/RelatedNoteModal.vue';

export default {
  name: 'RelatedNoteChip',
  components: { RelatedNoteModal },
  props: {
    proceedingId: { type: String, required: true },
    note: {
      type: Object || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>

<style lang="sass" scoped>
span.v-chip
  margin: .5rem 0 0 0
</style>
