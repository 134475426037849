<template>
  <v-container class="lighten-5 mb-6">
    <v-row gutters>
      <v-col>
        <GraphqlServerSideDataGrid
          enable-search
          :forced-filters="[
            {
              field: 'type',
              value: 'document',
              type: 'EQ',
            },
          ]"
          ref="grid"
          title="Dokumenty"
          :headers="headers"
          :query="DOCUMENT_LIST_QUERY"
        >
          <template v-slot:[`item.cardNumbers`]="{ item }">
            {{ item.cardNumbers.from | orEmpty }} - {{ item.cardNumbers.to | orEmpty }}
          </template>
          <template v-slot:[`item.kind`]="{ item }">
            <v-chip outlined small :color="item.kind | documentKindClass">
              {{ item.kind | documentKindName }}
            </v-chip>
          </template>

          <template v-slot:[`item.receivedAt`]="{ item }">
            <span v-if="item.envelope"> Nadanie: {{ item.envelope.createdAt | date }} </span>
            <span v-else> {{ item.receivedAt | date }} </span>
          </template>
          <template v-slot:[`item.proceeding`]="{ item }">
            {{ item.proceeding.signature }},
            <span v-for="debtor in item.proceeding.debtors" :key="`${debtor.name}-${debtor.surname}-${debtor.id}`">
              {{ `${debtor.name} ${debtor.surname},` }}
            </span>
          </template>
          <template v-slot:[`item.advisor`]="{ item }">
            <AdvisorChip small :advisor="item.proceeding.advisor" />
          </template>
          <template v-slot:[`item.name`]="{ item }">{{ item.name }}</template>
          <template v-slot:[`item.actions`]="{ item }">
            <DocumentPreviewModal :document-id="item.id" :max-embed-levels="3">
              <template v-slot:activator="{ openDialog }">
                <v-btn x-small outlined @click="openDialog">pokaż</v-btn>
              </template>
            </DocumentPreviewModal>
          </template>

          <template v-slot:[`item.receiverSender`]="{ item }">
            {{ item.kind | getSenderOrReceiverPrefix }}
            <span v-if="item.recipient">{{ item.recipient.name }}</span>
            <span v-else-if="item.recipientSender">{{ item.recipientSender.name }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <DocumentPreviewModal :document-id="item.id" :max-embed-levels="3">
              <template v-slot:activator="{ openDialog }">
                <v-btn x-small outlined class="mr-1 mb-1" @click="openDialog">pokaż</v-btn>
              </template>
            </DocumentPreviewModal>
            <v-btn
              v-if="item.acl_edit_content && item.__typename === 'InternalDocument'"
              @click="$router.push({ name: 'documents/edit', params: { id: item.id } })"
              outlined
              class="mr-1 mb-1"
              color="primary"
              x-small
            >
              edytuj treść
            </v-btn>
            <EditExternalDocumentForm
              v-if="item.acl_edit && item.__typename === 'ExternalDocument'"
              :proceeding-id="item.proceeding.id"
              :document-id="item.id"
            />
          </template>
        </GraphqlServerSideDataGrid>
        <AddExternalDocumentForm @done="$refs.grid.refetch()" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSenderOrReceiver, getSenderOrReceiverPrefix } from '@/helpers/documents';
import AddExternalDocumentForm from '@/components/Document/Form/AddExternalDocumentForm.vue';
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import DOCUMENT_LIST_QUERY from '@/graphql/document/query/DocumentListQuery.gql';
import DocumentPreviewModal from '@/components/Document/DocumentPreviewModal.vue';
import EditExternalDocumentForm from '@/components/Document/Form/EditExternalDocumentForm.vue';
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import FilterBySelectField from '@/components/Filters/FilterBySelectField.vue';
import FilterByDate from '@/components/Filters/FilterByDate.vue';

export default {
  name: 'DocumentsListPage',
  components: {
    EditExternalDocumentForm,
    AddExternalDocumentForm,
    DocumentPreviewModal,
    AdvisorChip,
    GraphqlServerSideDataGrid,
  },
  filters: {
    getSenderOrReceiver,
    getSenderOrReceiverPrefix,
  },
  methods: {
    queryVariablesChanged(newVars) {
      this.lastQueryVariables = newVars;
    },
  },
  data() {
    return {
      DOCUMENT_LIST_QUERY,
      headers: [
        {
          text: 'Rodzaj',
          sortable: true,
          value: 'kind',
          graphqlFilter: {
            component: FilterBySelectField,
            props: {
              items: [
                { text: 'Przychodząca - sąd', value: 'INCOMING_COURT' },
                { text: 'Wychodząca - sąd', value: 'OUTGOING_COURT' },
                { text: 'Przychodząca - wierzyciel', value: 'INCOMING_CREDITOR' },
                { text: 'Wychodząca - wierzyciel', value: 'OUTGOING_CREDITOR' },
                { text: 'Przychodząca - inne', value: 'INCOMING_OTHER' },
                { text: 'Wychodząca - inne', value: 'OUTGOING_OTHER' },
              ],
            },
          },
        },
        // { text: 'Nr kart', value: 'cardsCount' },
        {
          text: 'Termin doręczenia/nadania',
          value: 'receivedAt',
          sortable: true,
          graphqlFilter: { component: FilterByDate },
        },
        { text: 'Nr kart', value: 'cardNumbers', sortable: false },
        { text: 'Postępowanie', value: 'proceeding', sortable: false },
        { text: 'Nazwa', value: 'name', sortable: false },
        { text: 'Nadawca/Odbiorca', value: 'receiverSender', sortable: false },
        { text: 'Doradca', value: 'advisor', sortable: false },
        // { text: 'Powiązania', value: 'relations' },
        { text: 'Akcje', value: 'actions', sortable: false },
      ],
    };
  },
};
</script>
