<template>
  <div :style="style">
    <strong>{{ taskStatusName(task.due, task.finishedAt, task.status) }}</strong>
  </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
import { taskClass, taskStatusName } from '@/helpers/taskStatuses';

export default {
  name: 'TaskStatusChip',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    taskColor() {
      return taskClass(this.task.due, this.task.finishedAt, this.task.status);
    },
    style() {
      const color = colors[this.taskColor];
      return {
        textAlign: 'center',
        width: '160px',
        padding: '4px',
        borderWidth: '1px',
        borderRadius: '3px',
        borderStyle: 'solid',
        color: color.base,
        borderColor: color.base,
        backgroundColor: color.lighten5,
      };
    },
  },
  methods: {
    taskClass,
    taskStatusName,
  },
};
</script>
