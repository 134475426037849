<template>
  <span>
    <div v-for="note in notes" :key="note.id">
      <RelatedNoteChip
        v-if="notes"
        :note="note"
        :x-small="xSmall"
        :small="small"
        :medium="medium"
        :large="large"
        :proceeding-id="proceedingId"
      />
    </div>
  </span>
</template>

<script>
import RelatedNoteChip from '@/components/Common/RelatedNoteChip.vue';

export default {
  name: 'RelatedNotesChips',
  components: { RelatedNoteChip },
  props: {
    proceedingId: { type: String, required: true },
    notes: {
      type: Array || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>
