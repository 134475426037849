<template>
  <span>
    <v-autocomplete
      outlined
      :items="documents"
      item-value="id"
      item-text="name"
      :loading="globalLoading"
      v-model="internalValue"
      :disabled="globalLoading"
      prepend-icon="mdi-calendar"
      v-bind="$props"
      chips
      deletable-chips
      multiple
      solo
    >
    </v-autocomplete>
  </span>
</template>
<script>
import LIST_DOCUMENTS from '@/graphql/document/query/ProceedingRelatedDocumentsQuery.gql';

export default {
  name: 'DocumentsSelectField',
  props: {
    value: Array,
    label: String,
    rules: { type: Array },
    loading: { type: Boolean, default: false },
    proceedingId: { type: String, required: true },
  },
  computed: {
    globalLoading() {
      return this.$apollo.queries.documents.loading || this.loading;
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  apollo: {
    documents: {
      query: LIST_DOCUMENTS,
      update(data) {
        return data.list.items[0].documents;
      },
      variables() {
        return { proceedingId: this.proceedingId };
      },
    },
  },
};
</script>
