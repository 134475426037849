<template>
  <FilterBaseTemplate @propagate="propagate" :has-value="hasValue">
    <DateField dense label="od" clearable v-model="internalValueFrom" />
    <DateField dense label="do" clearable v-model="internalValueTo" />
  </FilterBaseTemplate>
</template>

<script>
import QUERY from '@/graphql/user/query/ListUsersQuery.gql';
import DateField from '@/components/FormFields/DateField.vue';
import FilterPropagateEventMixin from '@/components/Filters/FilterPropagateEventMixin';
import FilterBaseTemplate from '@/components/Filters/FilterBaseTemplate.vue';

export default {
  name: 'FilterByDate',
  components: { FilterBaseTemplate, DateField },
  mixins: [FilterPropagateEventMixin],
  data() {
    return {
      dates: [],
      internalValueFrom: null,
      internalValueTo: null,
    };
  },
  computed: {
    hasValue() {
      return this.internalValueTo !== null || this.internalValueFrom !== null;
    },
    dateRangeText() {
      return this.dates.join(' - ');
    },
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  methods: {
    propagate() {
      if (!this.internalValueFrom && !this.internalValueTo) {
        this.$emit('clearFilter', [{ field: this.header.value }]);
        return;
      }
      const emitData = [];
      if (this.internalValueFrom) {
        emitData.push({ field: this.header.value, type: 'GTE', value: this.internalValueFrom });
      }
      if (this.internalValueTo) {
        emitData.push({ field: this.header.value, type: 'LTE', value: this.internalValueTo });
      }
      this.$emit('applyFilter', emitData);
    },
  },
  apollo: {
    items: {
      query: QUERY,
      update: (data) => data.list.items,
    },
  },
};
</script>
