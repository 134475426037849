<template>
  <span v-if="document && document.__typename === 'InternalDocument'">
    <v-btn
      v-bind="$props"
      :disabled="!document.acl_edit"
      v-if="document.deliveryIntent !== 'DELIVER_BY_MAIL'"
      @click="mutate('DELIVER_BY_MAIL')"
      :loading="loading"
      class="mr-1"
    >
      Ustaw "Do wysłania"
    </v-btn>
    <v-btn
      v-bind="$props"
      :disabled="!document.acl_edit"
      v-if="document.deliveryIntent !== 'DELIVER_BY_HAND'"
      @click="mutate('DELIVER_BY_HAND')"
      :loading="loading"
      class="mr-1"
    >
      Ustaw "Do złożenia ręcznie"
    </v-btn>
  </span>
</template>

<script>
import MUTATION from '@/graphql/document/mutation/SetDocumentDeliveryIntentMutation.gql';

export default {
  name: 'SetDocumentDeliveryIntentButton',
  props: {
    document: {
      type: Object,
      required: true,
    },
    small: Boolean,
    xSmall: Boolean,
    large: Boolean,
    outlined: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    mutate(newIntent) {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: MUTATION,
          variables: {
            documentId: this.document.id,
            deliveryIntent: newIntent,
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
