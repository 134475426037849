<template>
  <div class="address-chip">
    <p>{{ address.street }}</p>
    <p>{{ address.postCode }} {{ address.city }}</p>
  </div>
</template>
<script>
export default {
  name: 'AddressChip',
  props: {
    address: {
      type: Object || null,
      required: true,
    },
  },
};
</script>
<style scoped>
.address-chip p {
  margin-bottom: 0px;
}
</style>
