<template>
  <span>
    <EditExternalDocumentForm
      v-if="document.acl_edit && document.__typename === 'ExternalDocument'"
      :document-id="document.id"
      :button-props="buttonProps"
      :proceeding-id="proceeding.id"
    />
    <EditInternalDocumentForm
      v-if="document.acl_edit && document.__typename === 'InternalDocument'"
      :document-id="document.id"
      :button-props="buttonProps"
      :proceeding-id="proceeding.id"
    />
  </span>
</template>
<script>
import EditExternalDocumentForm from '@/components/Document/Form/EditExternalDocumentForm.vue';
import EditInternalDocumentForm from '@/components/Document/Form/EditInternalDocumentForm.vue';

export default {
  name: 'DocumentEditFormButtons',
  components: { EditExternalDocumentForm, EditInternalDocumentForm },
  props: {
    document: { type: Object, required: true },
    proceeding: { type: Object, required: true },
    buttonProps: {
      type: Object,
      required: false,
      default: () => {
        return { 'x-small': true, 'outlined': true };
      },
    },
  },
};
</script>
