<template>
  <span>
    <v-autocomplete
      outlined
      item-value="id"
      item-text="label"
      :items="filteredDebtors"
      :loading="$apollo.queries.debtors.loading"
      v-model="internalValue"
      v-bind="$props"
      @click:append-outer="openDialog"
      prepend-icon="mdi-account-cash"
    >
      <template slot="selection" slot-scope="data">
        <v-chip
          ><v-icon>{{ data.item.__typename | typeNameToIcon }}</v-icon
          >{{ data.item.name }} {{ data.item.surname ? data.item.surname : null }}</v-chip
        >
      </template>
      <template slot="item" slot-scope="data">
        <v-icon>{{ data.item.__typename | typeNameToIcon }}</v-icon> {{ data.item.name }}
        {{ data.item.surname ? data.item.surname : null }}
      </template>
      <template slot="append-outer">
        <v-btn-toggle style="margin-top: -16px" v-if="displayAddButtons">
          <v-btn @click="personalDebtorDialog = true">
            <v-icon small left>mdi-plus</v-icon><v-icon>mdi-account</v-icon>
          </v-btn>
          <v-btn @click="companyDebtorDialog = true">
            <v-icon small left>mdi-plus</v-icon><v-icon>mdi-factory</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-autocomplete>
    <AddPersonalDebtorForm
      @close="personalDebtorDialog = false"
      :dialog="personalDebtorDialog"
      @newElement="selectNewElement"
      v-if="displayAddButtons"
    />
    <AddLegalPersonDebtorForm
      @close="companyDebtorDialog = false"
      :dialog="companyDebtorDialog"
      @newElement="selectNewElement"
      v-if="displayAddButtons"
    />
  </span>
</template>
<script>
import AddLegalPersonDebtorForm from '@/components/Debtor/AddLegalPersonDebtorForm.vue';
import AddPersonalDebtorForm from '@/components/Debtor/AddPersonalDebtorForm.vue';
import LIST_DEBTORS from '@/graphql/debtor/query/ListDebtorsForFormQuery.gql';

export default {
  name: 'DebtorSelectField',
  components: { AddLegalPersonDebtorForm, AddPersonalDebtorForm },
  props: {
    value: String,
    label: String,
    rules: { type: Array },
    proceedingId: {
      type: String,
      required: false,
      default: null,
    },
    displayAddButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
    formValues: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    typeNameToIcon(value) {
      if (value === 'PrivatePersonDebtor') {
        return 'mdi-account';
      }
      return 'mdi-factory';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'formValues.proceedingId': function () {
      const debtors = this.filteredDebtors;
      if (debtors.length === 1) {
        this.$emit('input', debtors[0].id);
      }
    },
  },
  data() {
    return {
      personalDebtorDialog: false,
      companyDebtorDialog: false,
    };
  },
  computed: {
    filteredDebtors() {
      if (!this.debtors) {
        return [];
      }
      if ((this.formValues && this.formValues.proceedingId) || this.proceedingId) {
        return this.debtors
          .filter((debtor) => {
            return (
              debtor.proceedings.filter((proceeding) => {
                return proceeding.id === (this.formValues.proceedingId || this.proceedingId);
              }).length > 0
            );
          })
          .map(this.mapDebtor);
      }
      return this.debtors.map(this.mapDebtor);
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    mapDebtor(debtor) {
      // eslint-disable-next-line no-param-reassign
      debtor.label = `${debtor.name} ${debtor.surname}`;
      return debtor;
    },
    selectNewElement({ id }) {
      // this will trigger input change and select that element
      this.$emit('input', id);
    },
    openDialog() {
      this.personalDebtorDialog = true;
    },
  },
  apollo: {
    debtors: {
      query: LIST_DEBTORS,
      update(data) {
        return data.listDebtors.items;
      },
    },
  },
};
</script>
