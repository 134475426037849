<template>
  <AbstractModalForm
    :form-specification="formSpecification"
    :mutation="MUTATION"
    :mutation-error-mapper="(data) => (data.data.debtor.errors ? data.data.debtor.errors : [])"
    :mutation-variables="
      (data) => {
        data.entityId = debtorId;
        return { input: data };
      }
    "
    :entity-id="debtorId"
    :query="QUERY"
    :query-variables="() => ({ debtorId })"
    :query-update="(data) => data.list.items[0]"
    :refetch-queries="['ProceedingDetails']"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </AbstractModalForm>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import MUTATION from '@/graphql/debtor/mutation/EditPrivatePersonDebtorMutation.gql';
import QUERY from '@/graphql/debtor/query/GetDebtorQuery.gql';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';

export default {
  name: 'EditPrivatePersonDebtorForm',
  components: { AbstractModalForm },
  data: () => {
    return {
      MUTATION,
      QUERY,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'name' },
                props: { 'label': 'Imię', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'surname' },
                props: { 'label': 'Nazwisko', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'nameDeclined' },
                props: { 'label': 'Imię odmienione', 'prepend-icon': 'mdi-account' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'surnameDeclined' },
                props: { 'label': 'Nazwisko odmienione', 'prepend-icon': 'mdi-account' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'email' },
                props: { 'label': 'E-mail', 'prepend-icon': 'mdi-email' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'phone' },
                props: { 'label': 'Nr. telefonu', 'prepend-icon': 'mdi-phone' },
                rules: [isRequiredValidator],
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'address.street' },
                props: { 'label': 'Ulica', 'prepend-icon': 'mdi-map-marker' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 4 },
                type: VTextField,
                model: { out: 'address.postCode' },
                props: { 'label': 'Kod pocztowy', 'prepend-icon': 'mdi-map-marker' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 8 },
                type: VTextField,
                model: { out: 'address.city' },
                props: { 'label': 'Miasto', 'prepend-icon': 'mdi-city' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    debtorId: {
      type: String,
      required: true,
    },
  },
  methods: {
    done() {},
  },
};
</script>
