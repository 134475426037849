<template>
  <span>
    <span v-for="document in documents" :key="document.id">
      <RelatedDocumentChip :document="document" v-bind="$props" />
    </span>
    <v-chip v-if="documents.length == 0" v-bind="$props">brak</v-chip>
  </span>
</template>

<script>
import RelatedDocumentChip from '@/components/Common/RelatedDocumentChip.vue';

export default {
  name: 'RelatedDocumentsChip',
  components: { RelatedDocumentChip },
  props: {
    documents: {
      type: Array || null,
      required: true,
    },
    maxEmbedLevels: {
      type: Number,
      required: false,
      default: () => 5,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
    outlined: { type: Boolean },
  },
};
</script>
