<template>
  <v-btn-toggle>
    <v-btn v-if="currentState" small :loading="loading" :color="mapClass(currentState)" disabled
      >{{ currentState | translate }}
    </v-btn>
    <v-btn
      small
      :outlined="outlined"
      :loading="loading"
      :color="mapClass(state)"
      @click="changeState(state)"
      v-for="(state, index) in availableStateTransitions"
      :key="index"
      >{{ state | translate }}
    </v-btn>
  </v-btn-toggle>
</template>
<script>
import { mapClass } from '@/helpers/taskStatuses';
import CHANGE_TASK_STATUS_MUTATION from '@/graphql/task/mutation/ChangeTaskStatusMutation.gql';

export default {
  name: 'StateTransitionButtons',
  data() {
    return {
      loading: false,
    };
  },
  filters: {
    translate(status) {
      switch (status) {
        case 'NEW':
          return 'nowy';
        case 'IN_PROGRESS':
          return 'w trakcie';
        case 'FINISHED':
          return 'ukończone';
        default:
          return status;
      }
    },
  },
  props: {
    availableStateTransitions: {
      type: Array,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    outlined: {
      type: Boolean,
      required: false,
    },
    currentState: {
      type: String,
      required: false,
    },
  },
  methods: {
    mapClass,
    async changeState(newStatus) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: CHANGE_TASK_STATUS_MUTATION,
          variables: { taskId: this.taskId, newStatus },
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
