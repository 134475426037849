<template>
  <div class="pdf-container" :key="url">
    <div class="parentPage" v-for="i in numPages" :key="i">
      <pdf :src="src" :page="i" style="display: inline-block; width: 100%">
        <template slot="loading">
          <VProgressLinear />
        </template>
      </pdf>
    </div>
  </div>
</template>

<script>
import pdf from 'pdfvuer';

export default {
  name: 'PdfViewer',
  components: {
    pdf,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  watch: {
    url(newUrl) {
      this.src = pdf.createLoadingTask({ url: newUrl, withCredentials: true });
      this.src.then((pdfResult) => {
        this.numPages = pdfResult.numPages;
      });
    },
  },
  data() {
    return {
      src: pdf.createLoadingTask({ url: this.url, withCredentials: true }),
      numPages: undefined,
    };
  },
  mounted() {
    this.src.then((pdfResult) => {
      this.numPages = pdfResult.numPages;
    });
  },
};
</script>
<style>
/* .page {
  box-shadow: 1px 1px 20px 1px black;
  width: 95%;
  margin-left: 2.5%;
  margin-top: 2.5%;
}
.parentPage {
  padding-right: 50px;
}
.pdf-container {
  width: 100%;
  min-height: 100%;
  background: grey;
  padding-top: 1%;
} */
</style>
