<template>
  <span>
    <v-dialog
      max-width="1200px"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
      @close="close"
      :value="showModal"
      @click:outside="close"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Podgląd</v-toolbar-title>
          <v-spacer></v-spacer>
          <slot name="toolbar"></slot>
        </v-toolbar>
        <v-container>
          <DocumentPreview
            :withoutActions="withoutActions"
            :max-embed-levels="maxEmbedLevels"
            :document-id="documentId"
            @close="close"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="activator" v-bind:openDialog="openDialog"></slot>
  </span>
</template>

<script>
import DocumentPreview from '@/components/Document/Component/DocumentPreview.vue';

export default {
  name: 'DocumentPreviewModal',
  components: { DocumentPreview },
  data() {
    return {
      showModal: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.showModal = newValue;
    },
  },
  computed: {},
  methods: {
    openDialog() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.$emit('close');
    },
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    documentId: {
      type: String,
      required: true,
    },
    maxEmbedLevels: {
      type: Number,
      required: false,
      default: () => 5,
    },
    withoutActions: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  apollo: {},
};
</script>
