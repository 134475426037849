<template>
  <div>
    <v-btn v-if="addEnabled" bottom color="pink" dark fab fixed right @click="$emit('addItem')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div class="about">
      <p>
        <strong>{{ title }}</strong>
      </p>
    </div>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <v-skeleton-loader v-if="!items" type="table"></v-skeleton-loader>
    <v-card v-if="items">
      <v-card-title>
        <v-btn v-if="refetch" small @click="$apollo.queries.items.refetch()">Odśwież</v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="showSearch"
          v-model="search"
          append-icon="mdi-search"
          label="Szukaj"
          single-line
          :hint="searchHint"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-bind="$props"
        ref="dataTable"
        :headers="headers"
        :items="items"
        :loading="!items"
        :options="{ sortBy: [sortBy], sortDesc: [true], itemsPerPage: itemsPerPage }"
        :search="search"
        @update:page="reemit('update:page', ...arguments)"
        @input="reemit('input', ...arguments)"
      >
        <!-- @click:row="reemit('click:row', ...arguments)" TODO: down cuz click works only for all row -->
        <template v-slot:[`item.active`]="{ item }">
          <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.due`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.user`]="{ item }">
          {{ item.user !== null ? item.user : 'brak' }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          {{ item.status | mapStatus }}
        </template>

        <template v-slot:[`item.finishedAt`]="{ item }">
          {{ (item.finishedAt === null && 'w trakcie') || item.finishedAt }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn v-if="item.acl_edit" x-small outlined @click="$emit('editItem', item)">edytuj</v-btn>
          <v-btn class="ml-2" x-small outlined @click="$emit('displayItem', item)">pokaż</v-btn>
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-data-table>
    </v-card>
    <slot id="forms"></slot>
  </div>
</template>

<script>
export default {
  name: 'DataGrid',
  data() {
    return {
      search: '',
      error: null,
    };
  },
  methods: {
    reemit(event, data) {
      this.$emit(event, data);
    },
  },
  computed: {
    computedOptions: {
      set() {
        // ignore
      },
      get() {
        return this.options;
      },
    },
    loading() {
      return this.$apollo.queries.items.loading;
    },
  },
  props: {
    showExpand: Boolean,
    singleExpand: Boolean,
    showSelect: Boolean,
    title: {
      type: String,
      required: false,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchHint: {
      type: String,
      required: false,
      default: null,
    },
    addEnabled: {
      type: Boolean,
      default: false,
    },
    refetch: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortBy: {
      type: String,
      required: false,
    },
    sortDesc: {
      type: String,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    variables: {
      type: Object,
      false: true,
      default: () => {},
    },
    value: {},
    update: {
      type: Function,
      default: function _default(data) {
        return data;
      },
    },
  },
};
</script>
