<template>
  <v-dialog
    max-width="800px"
    :value="dialog"
    persistent
    :fullscreen="false"
    :scrollable="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Dodaj dłużnika - osoba prywatna</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save" :loading="$apollo.loading">Zapisz</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container style="overflow: auto">
        <AbstractForm
          ref="addForm"
          :form-specification="formSpecification"
          :mutation="mutation"
          :mutation-error-mapper="(data) => (data.data.addDebtor.errors ? data.data.addDebtor.errors : [])"
          :mutation-variables="
            (data) => {
              return { personalDebtorInput: data };
            }
          "
          @updateStore="handleStoreUpdate"
        ></AbstractForm>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import ADD_PRIVATE_DEBTOR_MUTATION from '@/graphql/debtor/mutation/AddPersonalDebtorMutation.gql';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import LIST_DEBTORS from '@/graphql/debtor/query/ListDebtorsForFormQuery.gql';

export default {
  name: 'AddPersonalDebtorForm',
  components: { AbstractForm },
  data: () => {
    return {
      mutation: ADD_PRIVATE_DEBTOR_MUTATION,
      editFormLoading: false,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'name' },
                props: { 'label': 'Imię', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'surname' },
                props: { 'label': 'Nazwisko', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'nameDeclined' },
                props: { 'label': 'Imię odmienione', 'prepend-icon': 'mdi-account' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'surnameDeclined' },
                props: { 'label': 'Nazwisko odmienione', 'prepend-icon': 'mdi-account' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'email' },
                props: { 'label': 'E-mail', 'prepend-icon': 'mdi-email' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'phone' },
                props: { 'label': 'Nr. telefonu', 'prepend-icon': 'mdi-phone' },
                rules: [isRequiredValidator],
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'address.street' },
                props: { 'label': 'Ulica', 'prepend-icon': 'mdi-map-marker' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 4 },
                type: VTextField,
                model: { out: 'address.postCode' },
                props: { 'label': 'Kod pocztowy', 'prepend-icon': 'mdi-map-marker' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 8 },
                type: VTextField,
                model: { out: 'address.city' },
                props: { 'label': 'Miasto', 'prepend-icon': 'mdi-city' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleStoreUpdate({
      store,
      serverData: {
        data: { addDebtor },
      },
    }) {
      if (addDebtor.__typename === 'PrivatePersonDebtor') {
        // added successfully
        const allDebtors = store.readQuery({ query: LIST_DEBTORS });
        allDebtors.listDebtors.items.unshift(addDebtor);
        store.writeQuery({
          query: LIST_DEBTORS,
          data: allDebtors,
        });
        this.$emit('newElement', addDebtor);
        this.close();
        this.$refs.addForm.clear();
      }
    },
    save() {
      // todo remove validate, just for debug purposes
      // this.$refs.addForm.$refs.form.validate();
      // console.log(this.$refs.addForm.grabDebugVariables());
      this.$refs.addForm.mutate();
    },
  },
};
</script>
