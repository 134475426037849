<template>
  <span>
    <pre v-if="debug">
      Options:
      {{ options }}
      ---------------
      Query vars:
      {{ queryVariables }}
      ---------------
      Gql filters:
      {{ graphqlFilters }}
      ---------------
      Gql filters simplified:
      {{ graphqlFiltersSimplified }}
    </pre>
    <ServerSideDataGrid
      :itemsPerPage="options.itemsPerPage"
      @displayItem="reemit('displayItem', ...arguments)"
      :serverItemsLength="serverItemsLength"
      :loading="$apollo.queries.items.loading"
      @update:options="onUpdateOptions"
      @click:row="reemit('click:row', ...arguments)"
      v-bind="$props"
      ref="dataTable"
      :headers="headers"
      :items="items"
      :options="options"
      @refetch="refetch"
      @search="handleSearchChange"
      @input="reemit('input', ...arguments)"
    >
      <template v-for="header of graphqlHeaderFilter" v-slot:[`header.${header.value}`]>
        <div class="float-left mr-1" :key="`header.${header.value}`">{{ header.text }}</div>
        <div class="float-left" :key="`filter_body.${header.value}`">
          <component
            :key="header.value"
            :ref="`filter_${header.value}`"
            :is="header.graphqlFilter.component"
            :header="header"
            v-bind="header.graphqlFilter.props"
            @applyFilter="applyFilter(header, ...arguments)"
            @clearFilter="clearFilter(header, ...arguments)"
          />
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </ServerSideDataGrid>
  </span>
</template>
<script>
import { debounce } from 'debounce';
import ServerSideDataGrid from '@/components/Common/ServerSideDataGrid.vue';

export default {
  name: 'GraphqlServerSideDataGrid',
  components: { ServerSideDataGrid },
  props: {
    debug: { type: Boolean, default: false },
    showSelect: Boolean,
    headers: {
      type: Array,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sortBy: {
      type: Array,
      required: false,
      default: () => ['createdAt'],
    },
    dense: {
      type: Boolean,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortDesc: {
      type: Array,
      required: false,
      default: () => [true],
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    forcedFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: () => 15,
    },
  },
  computed: {
    graphqlHeaderFilter() {
      return this.headers.filter((header) => !!header.graphqlFilter);
    },
    graphqlFiltersSimplified() {
      const returnArray = [];
      // eslint-disable-next-line guard-for-in
      for (const filterGroupKey in this.graphqlFilters) {
        for (const filter of this.graphqlFilters[filterGroupKey]) {
          returnArray.push(filter);
        }
      }
      return returnArray;
    },
    queryVariables() {
      let offset = null;
      let limit = null;
      if (this.options.itemsPerPage > 0) {
        offset = (this.options.page - 1) * this.options.itemsPerPage;
        limit = this.options.itemsPerPage;
      }
      return {
        query: {
          orders: this.sortMapper,
          filters: [...this.graphqlFiltersSimplified, ...this.filters, ...this.forcedFilters, ...this.searchFilter],
        },
        limit,
        offset,
      };
    },
    sortMapper() {
      const tempArray = [];
      for (const key in this.options.sortBy) {
        if (typeof this.options.sortBy[key] === 'undefined') {
          continue;
        }
        tempArray.push({
          field: this.options.sortBy[key],
          direction: this.options.sortDesc[key] ? 'DESC' : 'ASC',
        });
      }
      return tempArray;
    },
  },
  data() {
    return {
      searchFilter: [],
      graphqlFilters: {},
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortBy,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      serverItemsLength: 0,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    handleSearchChange: debounce(function (newValue) {
      if (!newValue) {
        this.searchFilter = [];
        return;
      }

      this.searchFilter = [{ field: 'search', type: 'EQ', value: newValue }];
    }, 400),
    clearFilter(header, filterData) {
      const copyGraphqlFilters = this.graphqlFilters;
      delete copyGraphqlFilters[filterData[0].field];
      this.graphqlFilters = { ...copyGraphqlFilters };
    },
    applyFilter(header, filterData) {
      const newObject = {};
      newObject[filterData[0].field] = filterData;
      this.graphqlFilters = { ...this.graphqlFilters, ...newObject };
    },
    onUpdateOptions(options) {
      this.options = options;
    },
    reemit(event, data) {
      this.$emit(event, data);
    },
    refetch() {
      this.$apollo.queries.items.refetch();
    },
  },
  apollo: {
    items: {
      query() {
        return this.query;
      },
      variables() {
        return this.queryVariables;
      },
      update(data) {
        this.serverItemsLength = data.list.count;
        return data.list.items;
      },
    },
  },
};
</script>
