<template>
  <div>
    <v-dialog
      max-width="800px"
      persistent
      :value="dialog"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="purple">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodaj powiązane notatki</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save" :loading="loading">Zapisz</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <AbstractForm
            ref="setForm"
            :form-specification="formSpecification"
            :mutation="mutation"
            :mutation-variables="
              (data) => {
                data.documentId = documentId;
                return { input: data };
              }
            "
            :mutation-error-mapper="
              ({ data: { document } }) => (document.hasOwnProperty('errors') ? document.errors : [])
            "
            :query="query"
            :query-variables="() => ({ documentId })"
            :query-update="(data) => data.list.items[0]"
            :entity-id="documentId"
            @done="
              dialog = false;
              $emit('done');
            "
            @loading="handleLoading"
          >
          </AbstractForm>
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="activator" v-bind:openDialog="openDialog">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="purple" fab x-small dark v-bind="attrs" v-on="on" @click="openDialog">
            <v-icon>mdi-notebook-plus</v-icon>
          </v-btn>
        </template>
        <span>Powiąż notatkę</span>
      </v-tooltip>
    </slot>
  </div>
</template>

<script>
import AbstractForm from '@/components/Form/AbstractForm.vue';
import GET_ONE_DOCUMENT from '@/graphql/document/query/GetOneDocumentQuery.gql';
import NoteSelectField from '@/components/FormFields/NoteSelectField.vue';
import SET_DOCUMENT_RELATED_NOTES from '@/graphql/document/mutation/SetDocumentRelatedNotes.gql';

export default {
  name: 'SetDocumentRelatedNotesForm',
  components: { AbstractForm },
  data() {
    return {
      loading: false,
      dialog: false,
      mutation: SET_DOCUMENT_RELATED_NOTES,
      query: GET_ONE_DOCUMENT,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: NoteSelectField,
                model: { out: 'noteIds', in: 'relatedNotes' },
                inputModifier: (input) => (input === null ? [] : input.map((relatedNotes) => relatedNotes.id)),
                props: {
                  proceedingId: this.proceedingId,
                },
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    documentId: { type: String, required: true },
  },
  methods: {
    handleLoading(value) {
      this.loading = value;
    },
    openDialog() {
      this.dialog = true;
    },
    close(proceeding) {
      this.$emit('close', proceeding);
    },
    save() {
      this.$refs.setForm.mutate();
    },
  },
  apollo: {
    document: {
      query: GET_ONE_DOCUMENT,
      variables() {
        return {
          documentId: this.documentId,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
};
</script>

<style scoped></style>
