<template>
  <span>
    <ContactDetailsWidgetHoover :entity="judge">
      <template v-slot:activator="{ on }">
        <v-chip color="purple" dark v-bind="$props" v-if="judge" v-on="on">
          {{ judge.lastName }} {{ judge.firstName }}
        </v-chip>
      </template>
    </ContactDetailsWidgetHoover>
  </span>
</template>
<script>
import ContactDetailsWidgetHoover from '@/components/Common/ContactDetailsWidgetHoover.vue';

export default {
  name: 'JudgeChip',
  components: { ContactDetailsWidgetHoover },
  props: {
    judge: {
      type: Object || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>
