var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5 mb-6"},[_c('v-row',{attrs:{"gutters":""}},[_c('v-col',[_c('GraphqlServerSideDataGrid',{ref:"grid",attrs:{"enable-search":"","forced-filters":[
          {
            field: 'type',
            value: 'document',
            type: 'EQ',
          } ],"title":"Dokumenty","headers":_vm.headers,"query":_vm.DOCUMENT_LIST_QUERY},scopedSlots:_vm._u([{key:"item.cardNumbers",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("orEmpty")(item.cardNumbers.from))+" - "+_vm._s(_vm._f("orEmpty")(item.cardNumbers.to))+" ")]}},{key:"item.kind",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","color":_vm._f("documentKindClass")(item.kind)}},[_vm._v(" "+_vm._s(_vm._f("documentKindName")(item.kind))+" ")])]}},{key:"item.receivedAt",fn:function(ref){
        var item = ref.item;
return [(item.envelope)?_c('span',[_vm._v(" Nadanie: "+_vm._s(_vm._f("date")(item.envelope.createdAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.receivedAt))+" ")])]}},{key:"item.proceeding",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.proceeding.signature)+", "),_vm._l((item.proceeding.debtors),function(debtor){return _c('span',{key:((debtor.name) + "-" + (debtor.surname) + "-" + (debtor.id))},[_vm._v(" "+_vm._s(((debtor.name) + " " + (debtor.surname) + ","))+" ")])})]}},{key:"item.advisor",fn:function(ref){
        var item = ref.item;
return [_c('AdvisorChip',{attrs:{"small":"","advisor":item.proceeding.advisor}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('DocumentPreviewModal',{attrs:{"document-id":item.id,"max-embed-levels":3},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var openDialog = ref.openDialog;
return [_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"x-small":"","outlined":""},on:{"click":openDialog}},[_vm._v("pokaż")])]}}],null,true)}),(item.acl_edit_content && item.__typename === 'InternalDocument')?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"outlined":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.$router.push({ name: 'documents/edit', params: { id: item.id } })}}},[_vm._v(" edytuj treść ")]):_vm._e(),(item.acl_edit && item.__typename === 'ExternalDocument')?_c('EditExternalDocumentForm',{attrs:{"proceeding-id":item.proceeding.id,"document-id":item.id}}):_vm._e()]}},{key:"item.receiverSender",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getSenderOrReceiverPrefix")(item.kind))+" "),(item.recipient)?_c('span',[_vm._v(_vm._s(item.recipient.name))]):(item.recipientSender)?_c('span',[_vm._v(_vm._s(item.recipientSender.name))]):_vm._e()]}}],null,true)}),_c('AddExternalDocumentForm',{on:{"done":function($event){return _vm.$refs.grid.refetch()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }