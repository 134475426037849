<template>
  <v-container>
    <v-row>
      <v-col cols="4" v-if="document">
        <v-card>
          <v-toolbar dark color="success">
            <strong>
              {{
                `${document.proceeding.signature}, ${document.debtor.name} ${document.debtor.surname}, ${document.name}`
              }}
            </strong>
          </v-toolbar>
          <v-card-actions v-if="document.__typename === 'InternalDocument' && !withoutActions">
            <v-btn
              v-if="document.acl_edit_content"
              small
              class="mr-1"
              @click="$router.push({ name: 'documents/edit', params: { id: document.id } })"
            >
              edytuj treść
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="!withoutActions">
            <DocumentEditFormButtons
              :button-props="{ small: true }"
              v-if="document.acl_edit"
              :document="document"
              :proceeding="document.proceeding"
            />
            <SetBasicProceedingDocumentButton small :document="document" />
          </v-card-actions>
          <v-card-text class="secondary white--text">Szczegóły</v-card-text>
          <div>
            <v-card-text>
              Sygn. akt: <strong>{{ document.proceeding.signature }}</strong>
            </v-card-text>
            <v-card-text>
              Doradca:
              <AdvisorChip :advisor="document.proceeding.advisor" />
            </v-card-text>
            <v-card-text>
              Dłużnik:
              <DebtorChip :debtor="document.debtor" />
            </v-card-text>
            <v-card-text>
              Rodzaj:
              <v-chip outlined :color="document.kind | documentKindClass">
                {{ document.kind | documentKindName }}
              </v-chip>
            </v-card-text>
            <v-divider />
            <v-card-text v-if="document.recipientSender">
              Od/do:
              <RecipientAddressChip :recipient="document.recipientSender" />
            </v-card-text>
            <v-card-text v-if="document.recipient">
              Do:
              <RecipientAddressChip :recipient="document.recipient" />
            </v-card-text>
            <v-card-text>
              <div v-if="document.status">
                Status:
                <DocumentShipmentStatus :document="document" />
                <br />
                <br />
              </div>
              <span v-if="document.envelope && document.envelope.trackingNumber">
                Numer nadania: <strong>{{ document.envelope.trackingNumber }}</strong>
              </span>
              <span v-if="document.envelope && document.envelope.deliveryConfirmation">
                Potwierdzenie odbioru: <a :href="document.envelope.deliveryConfirmation.downloadUrl"> pobierz </a>
              </span>
              <span v-if="document.__typename === 'ExternalDocument'">Odebrane {{ document.receivedAt | date }}</span>
              <br />
              <SetShipmentForm
                @done="refetchData()"
                v-if="(document.isSendable || !!document.envelope) && !withoutActions"
                :document="document"
              />
            </v-card-text>
            <v-card-text v-if="document.envelope && document.envelope.actionDate">
              Termin doręczenia / nadania
              <v-chip outlined> {{ document.envelope.actionDate | date }}</v-chip>
            </v-card-text>
            <v-card-text
              v-if="document.__typename === 'InternalDocument' && !document.isSent && document.deliveryIntent"
            >
              Sposób dostarczenia: <strong>{{ document.deliveryIntent | mapDeliveryIntent }}</strong
              ><br />
              <SetDocumentDeliveryIntentButton outlined x-small :document="document" />
            </v-card-text>
            <v-divider />
            <v-card-text>
              <strong>Dokumenty powiązane</strong><br />
              <SetDocumentRelatedDocuments
                v-if="document.acl_edit"
                :proceedingId="document.proceeding.id"
                :document-id="document.id"
                class="mt-2 mb-4"
              />
              <RelatedDocumentsChip
                v-if="document.relatedDocuments.length > 0"
                :documents="document.relatedDocuments"
                small
                :max-embed-levels="maxEmbedLevels"
              />
              <v-card-text v-else>brak</v-card-text>
            </v-card-text>
            <v-card-text>
              <strong>Zadania powiązane</strong>
              <SetDocumentRelatedTasks
                v-if="document.acl_edit"
                :proceedingId="document.proceeding.id"
                :document-id="document.id"
                class="mt-2 mb-4"
              />
              <v-card-text v-if="document.relatedTasks.length === 0">brak powiązanych dokumentów </v-card-text>
              <RelatedTasksChip small v-if="document.relatedTasks.length > 0" :tasks="document.relatedTasks" />
            </v-card-text>

            <v-card-text>
              <strong>Notatki powiązane</strong>
              <SetDocumentRelatedNotesForm
                v-if="document.acl_edit"
                :proceeding-id="document.proceeding.id"
                :document-id="document.id"
                class="mt-2 mb-4"
              />
              <v-card-text v-if="document.relatedNotes.length === 0">brak powiązanych notatek </v-card-text>
              <RelatedNotesChips
                small
                v-if="document.relatedNotes.length > 0"
                :notes="document.relatedNotes"
                :proceeding-id="document.proceeding.id"
              />
            </v-card-text>
          </div>
          <v-divider />
          <v-card-actions>
            <v-btn x-small outlined @click="goToProceeding()">Idź do postępowania</v-btn>
          </v-card-actions>
          <v-card-actions>
            <AddTaskForm :proceeding-id="document.proceeding.id">
              <template v-slot:activator="{ openDialog }">
                <v-btn x-small outlined :disabled="!document.proceeding.acl_edit" @click="openDialog"
                  >Dodaj nowe zadanie do postępowania
                </v-btn>
              </template>
            </AddTaskForm>
          </v-card-actions>
          <v-card-actions>
            <AddExternalDocumentForm :proceeding-id="document.proceeding.id">
              <template v-slot:activator="{ openDialog }">
                <v-btn x-small outlined :disabled="!document.proceeding.acl_edit" @click="openDialog"
                  >Dodaj dokument do postępowania
                </v-btn>
              </template>
            </AddExternalDocumentForm>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="document" style="min-height: 100%" class="pr-0 pl-0">
        <PdfViewer
          :v-key="document.file.checksum"
          v-if="document.file"
          :url="`${document.file.downloadUrl}?version=${document.file.checksum}`"
        />
        <div v-else>Dokument nie został jeszcze zapisany do pliku PDF</div>
      </v-col>
      <v-col cols="4" v-if="!document">
        <v-skeleton-loader class="mx-auto" type="card" />
      </v-col>
      <v-col cols="8" v-if="!document">
        <v-skeleton-loader class="mx-auto" type="article" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import DebtorChip from '@/components/Common/DebtorChip.vue';
import DocumentEditFormButtons from '@/components/Proceeding/Tabs/DocumentEditFormButtons.vue';
import DocumentShipmentStatus from '@/components/Proceeding/Tabs/DocumentShipmentStatus.vue';
import GET_ONE_DOCUMENT from '@/graphql/document/query/GetOneDocumentQuery.gql';
import PdfViewer from '@/components/Document/Component/PdfViewer.vue';
import RecipientAddressChip from '@/components/Common/RecipientAddressChip.vue';
import RelatedDocumentsChip from '@/components/Common/RelatedDocumentsChip.vue';
import RelatedNotesChips from '@/components/Common/RelatedNotesChips.vue';
import RelatedTasksChip from '@/components/Common/RelatedTasksChip.vue';
import SetBasicProceedingDocumentButton from '@/components/Document/Component/SetBasicProceedingDocumentButton.vue';
import SetDocumentDeliveryIntentButton from '@/components/Document/Component/SetDocumentDeliveryIntentButton.vue';
import SetDocumentRelatedDocuments from '@/components/Document/Form/SetDocumentRelatedDocuments.vue';
import SetDocumentRelatedNotesForm from '@/components/Document/Form/SetDocumentRelatedNotesForm.vue';
import SetDocumentRelatedTasks from '@/components/Document/Form/SetDocumentRelatedTasks.vue';
import SetShipmentForm from '@/components/Document/Component/SetShipmentForm.vue';
import AddExternalDocumentForm from '@/components/Document/Form/AddExternalDocumentForm.vue';
import AddTaskForm from '@/components/Task/Form/AddTaskForm.vue';

export default {
  name: 'DocumentPreview',
  components: {
    AddTaskForm,
    AddExternalDocumentForm,
    SetDocumentDeliveryIntentButton,
    SetShipmentForm,
    DocumentEditFormButtons,
    SetBasicProceedingDocumentButton,
    DocumentShipmentStatus,
    RecipientAddressChip,
    SetDocumentRelatedDocuments,
    PdfViewer,
    DebtorChip,
    AdvisorChip,
    RelatedDocumentsChip,
    RelatedTasksChip,
    RelatedNotesChips,
    SetDocumentRelatedNotesForm,
    SetDocumentRelatedTasks,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
    maxEmbedLevels: {
      type: Number,
      required: false,
      default: () => 5,
    },
    withoutActions: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    refetchData() {
      this.$apollo.queries.document.refetch();
    },
    goToProceeding() {
      // this.$emit('close');
      this.$router.push({ name: 'proceedings/details', params: { id: this.document.proceeding.id } });
    },
  },
  apollo: {
    document: {
      query: GET_ONE_DOCUMENT,
      variables() {
        return {
          documentId: this.documentId,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
};
</script>

<style lang="sass" scoped>
li
  margin: .5rem 0 0 0
  list-style: none
</style>
