var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px","persistent":"","value":_vm.dialog,"fullscreen":false,"scrollable":false,"transition":"dialog-bottom-transition"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"purple"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Dodaj powiązane notatki")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Zapisz")])],1)],1),_c('v-container',[_c('AbstractForm',{ref:"setForm",attrs:{"form-specification":_vm.formSpecification,"mutation":_vm.mutation,"mutation-variables":function (data) {
              data.documentId = _vm.documentId;
              return { input: data };
            },"mutation-error-mapper":function (ref) {
                        var document = ref.data.document;

                        return (document.hasOwnProperty('errors') ? document.errors : []);
},"query":_vm.query,"query-variables":function () { return ({ documentId: _vm.documentId }); },"query-update":function (data) { return data.list.items[0]; },"entity-id":_vm.documentId},on:{"done":function($event){_vm.dialog = false;
            _vm.$emit('done');},"loading":_vm.handleLoading}})],1)],1)],1),_vm._t("activator",function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"purple","fab":"","x-small":"","dark":""},on:{"click":_vm.openDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-notebook-plus")])],1)]}}])},[_c('span',[_vm._v("Powiąż notatkę")])])]},{"openDialog":_vm.openDialog})],2)}
var staticRenderFns = []

export { render, staticRenderFns }