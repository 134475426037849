<template>
  <!--  TODO: remove div -->
  <div>
    <v-dialog
      max-width="800px"
      persistent
      :value="!!taskId"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
      @close="$emit('close')"
    >
      <v-card>
        <v-toolbar dark color="orange">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edytuj zadanie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save" :loading="$apollo.loading">Zapisz</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container style="overflow: auto">
          <AbstractForm
            ref="editForm"
            :form-specification="formSpecification"
            :query="query"
            :entity-id="taskId"
            :query-variables="(taskId) => ({ taskId: taskId })"
            :query-update="(data) => data.list.items[0]"
            :mutation="mutation"
            :mutationVariables="
              (data) => {
                let input = data;
                data.taskId = taskId;
                return { input };
              }
            "
            :mutation-error-mapper="(data) => (data.data.editTask.errors ? data.data.editTask.errors : [])"
            @done="$emit('close')"
          >
          </AbstractForm>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VTextField, VTextarea } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import AdvisorSelectField from '@/components/FormFields/AdvisorSelectField.vue';
import EDIT_TASK_MUTATION from '@/graphql/task/mutation/EditTaskMutation.gql';
import TASK_DETAILS_QUERY from '@/graphql/task/query/TaskDetailsQuery.gql';

export default {
  name: 'EditTaskForm',
  components: {
    AbstractForm,
  },
  data() {
    return {
      query: TASK_DETAILS_QUERY,
      mutation: EDIT_TASK_MUTATION,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: {
                  'label': 'Tytuł',
                  'prepend-icon': 'mdi-format-title',
                },
                rules: [isRequiredValidator],
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 12 },
                type: AdvisorSelectField,
                model: { out: 'userId' },
                props: { 'label': 'Osoba odpowiedzialna', 'prepend-icon': 'mdi-account-outline', 'deletable': true },
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextarea,
                model: { out: 'description' },
                props: { 'label': 'Treść', 'prepend-icon': 'mdi-text-box-outline' },
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    taskId: {
      type: String,
      required: false,
    },
  },
  methods: {
    save() {
      this.$refs.editForm.mutate();
    },
  },
};
</script>

<style scoped></style>
