<template>
  <span>
    <v-autocomplete
      outlined
      :items="tasks"
      item-value="id"
      item-text="name"
      :loading="globalLoading"
      v-model="internalValue"
      :disabled="globalLoading"
      prepend-icon="mdi-calendar"
      v-bind="$props"
      chips
      deletable-chips
      multiple
      solo
    >
    </v-autocomplete>
  </span>
</template>
<script>
import LIST_TASKS from '@/graphql/task/query/GetTasksByProceedingQuery.gql';

export default {
  name: 'TasksSelectField',
  props: {
    value: Array,
    label: String,
    rules: { type: Array },
    loading: { type: Boolean, default: false },
    proceedingId: { type: String, required: true },
  },
  computed: {
    globalLoading() {
      return this.$apollo.queries.tasks.loading || this.loading;
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  apollo: {
    tasks: {
      query: LIST_TASKS,
      update(data) {
        return data.list.items;
      },
      variables() {
        return { proceedingId: this.proceedingId };
      },
    },
  },
};
</script>
