<template>
  <div>
    <AbstractModalForm
      v-if="modal"
      :button-props="buttonProps"
      ref="editModalForm"
      label="Edytuj Dane"
      :form-specification="formSpecification"
      :mutation="MUTATION"
      :mutation-error-mapper="(data) => (data.data.document.errors ? data.data.document.errors : [])"
      :refetch-queries="refetchQueries"
      :mutation-variables="
        (data) => {
          delete data.entityId;
          data.documentId = documentId;
          return { input: data };
        }
      "
      :entity-id="documentId"
      :query="QUERY"
      :query-variables="() => ({ documentId })"
      :query-update="(data) => data.list.items[0]"
      @done="
        $emit('done');
        showModal = false;
      "
    />
    <AbstractForm
      v-else
      ref="editForm"
      :form-specification="formSpecification"
      :mutation="MUTATION"
      :mutation-error-mapper="(data) => (data.data.document.errors ? data.data.document.errors : [])"
      :refetch-queries="refetchQueries"
      :mutation-variables="
        (data) => {
          delete data.entityId;
          data.documentId = documentId;
          return { input: data };
        }
      "
      :entity-id="documentId"
      :query="QUERY"
      :query-variables="() => ({ documentId })"
      :query-update="(data) => data.list.items[0]"
    />
  </div>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import DateField from '@/components/FormFields/DateField.vue';
import MUTATION from '@/graphql/document/mutation/EditInternalDocumentMutation.gql';
import QUERY from '@/graphql/document/query/GetOneDocumentQuery.gql';
import CardNumbersField from '@/components/FormFields/CardNumbersField.vue';
import RecipientSelectFromProceedingField from '@/components/FormFields/Document/RecipientSelectFromProceedingField.vue';
import DocumentTypeSelectField from '@/components/FormFields/Document/DocumentTypeSelectField.vue';

export default {
  name: 'EditInternalDocumentForm',
  components: { AbstractForm, AbstractModalForm },
  data() {
    return {
      MUTATION,
      QUERY,
      formSpecification: {
        fields: [
          {
            columns: [
              // externalDocumentId: ID!
              // {
              //   cols: { md: 12 },
              //   type: DebtorSelectField,
              //   model: { out: 'debtorId' },
              //   props: {
              //     label: 'Dłużnik',
              //     proceedingId: this.proceedingId,
              //   },
              //   rules: [isRequiredValidator],
              // },
              // name: String!
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'Nazwa dokumentu' },
                rules: [isRequiredValidator],
              },
              // kind: DocumentKindEnum!
              {
                cols: { md: 12 },
                type: DocumentTypeSelectField,
                boundListeners: {
                  input({ form }) {
                    // return actual listener
                    return (value) => {
                      if (!form.entity) {
                        return;
                      }
                      const { court } = form.entity.proceeding;
                      switch (value) {
                        case 'INCOMING_COURT':
                        case 'OUTGOING_COURT':
                          form.updateFormValues({
                            'recipient.address.postCode': court.address.postCode,
                            'recipient.address.country': court.address.country,
                            'recipient.address.street': court.address.street,
                            'recipient.address.city': court.address.city,
                            'recipient.name': court.name,
                          });
                          break;
                        default:
                          break;
                      }
                    };
                  },
                },
                model: { out: 'kind' },
                props: {
                  'outgoingOnly': true,
                  'label': 'Rodzaj dokumentu',
                  'prepend-icon': 'mdi-identifier',
                },
                rules: [isRequiredValidator],
              },

              // date: DateTime!
              {
                cols: { md: 6 },
                type: DateField,
                initialData: () => new Date().toISOString(),
                model: { out: 'date' },
                props: { label: 'Data pisma' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: CardNumbersField,
                model: { out: 'cardNumbers' },
                props: { label: 'Numery kart' },
                rules: [],
              },
              {
                cols: { md: 12 },
                skip: ({ model }) => {
                  if (!model.kind) {
                    return true; // skip if kind is not selected
                  }
                  // if kind is equal to INCOMING_CREDITOR or OUTGOING_CREDITOR
                  switch (model.kind) {
                    case 'INCOMING_CREDITOR':
                    case 'OUTGOING_CREDITOR':
                      return false; // dont skip
                    default:
                      return true; // by default - skip
                  }
                },
                type: RecipientSelectFromProceedingField,
                model: { out: 'entityId' },
                props: {
                  label: 'Wybierz podmiot',
                  onlyEntities: true,
                  proceedingId: this.proceedingId,
                  // this function maps data from entity into event data
                  // ( see src/components/FormFields/Document/RecipientSelectFromProceedingField.vue:65 )
                  buildFunction: (name, street, city, country, code) => {
                    return {
                      'recipient.name': name,
                      'recipient.address.street': street,
                      'recipient.address.city': city,
                      'recipient.address.country': country,
                      'recipient.address.postCode': code,
                    };
                  },
                },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'recipient.name' },
                props: { label: 'Nazwa odbiorcy / nadawcy' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'recipient.address.street' },
                props: { label: 'Adres' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'recipient.address.postCode' },
                props: { label: 'Kod pocztowy' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'recipient.address.city' },
                props: { label: 'Miasto' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: () => true,
    },
    refetchQueries: {
      type: Array,
      default: () => [],
    },
    documentId: {
      type: String,
      required: true,
    },
    proceedingId: {
      type: String,
      required: true,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: () => {
        return { 'x-small': true, 'outlined': true };
      },
    },
  },
};
</script>
