<template>
  <span>
    <v-dialog
      max-width="1000px"
      persistent
      :value="true"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
    >
      <v-card :loading="!schedule">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Harmonogram</v-toolbar-title>
        </v-toolbar>
        <v-container v-if="schedule">
          <v-card-text class="pt-0 pb-0">
            Postępowanie: {{ schedule.proceeding.signature }}
            <DebtorListChip x-small :debtors="schedule.proceeding.debtors" />
          </v-card-text>
          <v-card-text class="pt-0 pb-0"> Sąd: {{ schedule.proceeding.court.name }} </v-card-text>
          <v-card-text class="pt-0 pb-0">
            Sędzia: <JudgeChip x-small :judge="schedule.proceeding.judge" />
          </v-card-text>
          <v-card-text class="pt-0 pb-0">
            Doradca: {{ schedule.proceeding.signature }} <AdvisorChip x-small :advisor="schedule.proceeding.advisor" />
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Status</th>
                    <th class="text-left">Zadanie</th>
                    <th class="text-left">Termin</th>
                    <th class="text-left">Wykonane</th>
                    <th class="text-left">Odpowiedzialny</th>
                    <th class="text-left">Powiazania</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in schedule.tasks" :key="item.id">
                    <td><TaskStatusChip :task="item" /> <br /></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.due | dateFrom }}</td>
                    <td>{{ item.finishedAt | date | orDash }}</td>
                    <td><AdvisorChip :advisor="item.user" v-if="item.user" /></td>
                    <td>
                      <TaskDetailsModal :taskId="item.id">
                        <template v-slot:activator="{ openDialog }">
                          <v-btn v-bind="$props" @click="openDialog" outlined x-small>Pokaż</v-btn>
                        </template>
                      </TaskDetailsModal>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-container>
      </v-card>
      <v-card v-if="!schedule">
        <v-skeleton-loader
          class="mx-auto"
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import DebtorListChip from '@/components/Common/DebtorsListChip.vue';
import JudgeChip from '@/components/Common/JudgeChip.vue';
import QUERY from '@/graphql/schedule/query/ScheduleDetailsQuery.gql';
import TaskDetailsModal from '@/components/Task/TaskDetailsModal.vue';
import TaskStatusChip from '@/components/Common/Task/TaskStatusChip.vue';

export default {
  name: 'ScheduleDetailsModal',
  components: { TaskDetailsModal, TaskStatusChip, JudgeChip, AdvisorChip, DebtorListChip },
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    scheduleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$router.push({ name: 'schedules' });
      this.$emit('close');
    },
  },
  apollo: {
    schedule: {
      query: QUERY,
      variables() {
        return { id: this.scheduleId };
      },
      update: (data) => data.list.items[0],
    },
  },
};
</script>
