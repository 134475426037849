var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SetRelatedDocumentsForm',{attrs:{"proceeding-id":_vm.proceedingId,"entity-id":_vm.taskId,"query":_vm.query,"query-variables":function () { return ({ taskId: _vm.taskId }); },"query-update":function (data) { return data.list.items[0]; },"mutation":_vm.mutation,"mutation-error-mapper":function (ref) {
    var task = ref.data.task;

    return (task.hasOwnProperty('errors') ? task.errors : []);
},"mutation-variables":function (data, entityId) {
      data.taskId = entityId;
      return { input: data };
    }},on:{"done":function($event){return _vm.$emit('done')}}})}
var staticRenderFns = []

export { render, staticRenderFns }