<template>
  <span v-if="document">
    <v-btn
      v-bind="$props"
      :disabled="!document.acl_edit"
      v-if="document.basicProceedingDocument"
      @click="mutate"
      :loading="loading"
    >
      Usuń z głównych
    </v-btn>
    <v-btn v-bind="$props" :disabled="!document.acl_edit" v-else @click="mutate" :loading="loading">
      Dodaj do głównych
    </v-btn>
  </span>
</template>

<script>
import MUTATION from '@/graphql/document/mutation/SetBasicProceedingDocumentMutation.gql';

export default {
  name: 'SetBasicProceedingDocumentButton',
  props: {
    document: {
      type: Object,
      required: true,
    },
    small: Boolean,
    xSmall: Boolean,
    large: Boolean,
    outlined: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    mutate() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: MUTATION,
          variables: {
            documentId: this.document.id,
            basicProceedingDocument: !this.document.basicProceedingDocument,
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
