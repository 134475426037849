var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AbstractModalForm',{key:_vm.key,attrs:{"label":"Dodaj dokument","form-specification":_vm.formSpecification,"mutation":_vm.mutation,"mutation-error-mapper":function (data) { return (data.data.document.errors ? data.data.document.errors : []); },"refetch-queries":_vm.refetchQueries,"mutation-variables":function (data) {
        if (this$1.proceedingId) {
          data.proceedingId = this$1.proceedingId;
        }
        return { input: data };
      }},on:{"close":function($event){return _vm.randomizeKey()},"done":function($event){return _vm.$emit('done')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var openDialog = ref.openDialog;
      var buttonProps = ref.buttonProps;
return [_vm._t("activator",function(){return [_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed-":"","right":""},on:{"click":openDialog}},[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]},{"openDialog":openDialog,"buttonProps":buttonProps})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }