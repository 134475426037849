<template>
  <div>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small text :color="hasValue ? 'primary' : 'grey'" dark-- v-bind="attrs" v-on="on">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-card class="pt-2 pb-2 pl-2 pr-2" style="width: 400px">
        <v-row>
          <v-col>
            <slot name="default" />
          </v-col>
          <v-col cols="4">
            <v-btn @click="$emit('propagate')">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'FilterBaseTemplate',
  props: {
    hasValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
