var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5 mb-6"},[_c('v-row',{attrs:{"gutters":""}},[_c('v-col',[(_vm.scheduleId)?_c('ScheduleDetailsModal',{attrs:{"scheduleId":_vm.scheduleId}}):_vm._e(),_c('GraphqlServerSideDataGrid',{attrs:{"title":"Harmonogramy","headers":_vm.headers,"sort-by":['id'],"query":_vm.QUERY,"enable-search":""},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.proceeding",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proceeding.signature)+" "),_c('DebtorListChip',{attrs:{"raw":"","debtors":item.proceeding.debtors}})]}},{key:"item.advisor",fn:function(ref){
var item = ref.item;
return [_c('AdvisorChip',{attrs:{"advisor":item.proceeding.advisor}})]}},{key:"item.court",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proceeding.court.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mapStatus")(item.proceeding.status))+" ")]}},{key:"item.taskInfo",fn:function(ref){
var item = ref.item;
return [_c('TaskSummaryHelper',{attrs:{"schedules":[item]}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }