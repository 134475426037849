<template>
  <div>
    <h1 class="display-2 primary--text">Whoops, 404</h1>
    <p>The page you were looking for does not exist</p>
  </div>
</template>

<script>
export default {
  name: 'FourOhFour',
  props: {
    lang: {
      type: String,
      default: 'en',
    },
  },
  created() {
    // this.$ssrContext && this.$ssrContext.res.status(404);
  },
};
</script>
