import moment from 'moment';
import { formatDate, formatDateTime, formatDateTimeReversed, time } from '@/plugins/filters';

export const formatOneDue = (toDate, wholeDay) => {
  if (wholeDay) {
    return formatDate(toDate);
  }
  return formatDateTime(toDate);
};
const sameDates = (dateA, dateB) => {
  return moment(dateA).isSame(moment(dateB), 'day');
};

export const formatTwoDue = (fromDate, toDate, wholeDay) => {
  if (wholeDay) {
    if (sameDates(fromDate, toDate)) {
      return formatDate(toDate);
    }
    return `${formatDate(fromDate)} - ${formatDate(toDate)}`;
  }

  if (!wholeDay) {
    if (sameDates(fromDate, toDate)) {
      return `${formatDateTime(fromDate)} - ${time(toDate)}`;
    }
  }
  return `${formatDateTime(fromDate)} - ${formatDateTimeReversed(toDate)}`;
};
const isValidDate = (value) => {
  return moment(value, 'YYYY-MM-DD', false).isValid();
};
export const formatDue = (task) => {
  const { beginning, due } = task;

  // validate beginning date
  if (!isValidDate(beginning)) {
    return formatOneDue(due, task.wholeDay);
  }

  return formatTwoDue(beginning, due, task.wholeDay);
};
