<template>
  <div class="mt-3">
    <TaskDetailsModal :taskId="task.id">
      <template v-slot:activator="{ openDialog }">
        <v-btn v-bind="$props" @click="openDialog">{{ task.name }}</v-btn>
      </template>
    </TaskDetailsModal>
  </div>
</template>

<script>
export default {
  name: 'RelatedTaskChip',
  components: {
    TaskDetailsModal: () => import('@/components/Task/TaskDetailsModal.vue'),
  },
  props: {
    task: {
      type: Object || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>

<style lang="sass" scoped>
span.v-chip
  margin: .5rem 0 0 0
</style>
