<template>
  <span>
    <div v-for="task in tasks" :key="task.id">
      <RelatedTaskChip v-if="tasks" :task="task" :x-small="xSmall" :small="small" :medium="medium" :large="large" />
    </div>
  </span>
</template>

<script>
import RelatedTaskChip from '@/components/Common/RelatedTaskChip.vue';

export default {
  name: 'RelatedTasksChip',
  components: {
    RelatedTaskChip,
  },
  props: {
    tasks: {
      type: Array || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>
