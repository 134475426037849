<template>
  <div>
    <v-chip class="mr-1" v-for="status in allStatuses" :key="status.label" x-small dark :color="status.color"
      >{{ status.count }} {{ status.label }}
    </v-chip>
  </div>
</template>
<script>
import { mapClass } from '@/helpers/taskStatuses';

export default {
  name: 'TaskSummaryHelper',
  computed: {
    allStatuses() {
      const data = [];

      const finishedLength = this.filterByStatus('FINISHED').length;
      const newLength = this.filterByStatus('NEW').length;
      const inProgressLength = this.filterByStatus('IN_PROGRESS').length;
      if (finishedLength > 0) {
        data.push({ color: mapClass('FINISHED'), label: 'ukończone', count: finishedLength });
      }
      if (newLength > 0) {
        data.push({ color: mapClass('NEW'), label: 'nowe', count: newLength });
      }
      if (inProgressLength > 0) {
        data.push({ color: mapClass('IN_PROGRESS'), label: 'w trakcie', count: inProgressLength });
      }
      return data;
    },
  },
  methods: {
    mapClass,
    filterByStatus(status) {
      const data = [];
      for (const schedule of this.schedules) {
        for (const task of schedule.tasks) {
          if (task.status !== status) {
            continue;
          }
          data.push(task);
        }
      }
      return data;
    },
  },
  props: {
    schedules: {
      type: Array,
      required: true,
    },
  },
};
</script>
