export default {
  methods: {
    emitPropagateEvent(field, type, value) {
      if (!value) {
        this.$emit('clearFilter', [{ field }]);
        return;
      }
      this.$emit('applyFilter', [{ field, type, value }]);
    },
    emitPropagateFromHeader(type, value) {
      this.emitPropagateEvent(this.header.value, type, value);
    },
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
};
