<template>
  <div>
    <v-dialog
      max-width="600px"
      persistent
      :value="dialog"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Zmiana terminu</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save" :loading="loading">Zapisz</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <AbstractForm
            ref="changeDate"
            :form-specification="formSpecification"
            :entity-id="taskId"
            :query="query"
            :query-variables="(id) => ({ id: taskId })"
            :query-update="(data) => data.list.items[0]"
            :mutation="mutation"
            :mutation-variables="
              (data) => {
                data.taskId = taskId;
                return data;
              }
            "
            :mutation-error-mapper="
              (data) => (data.data.changeTaskDueDate.errors ? data.data.changeTaskDueDate.errors : [])
            "
            @done="
              dialog = false;
              loading = false;
              $emit('done');
            "
          />
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="activator" v-bind:openDialog="openDialog">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab x-small dark v-bind="attrs" v-on="on" @click="openDialog">
            <v-icon>mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
        <span>Zmień termin</span>
      </v-tooltip>
    </slot>
  </div>
</template>

<script>
import { VSwitch } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import CHANGE_DUE_DATE_MUTATION from '@/graphql/task/mutation/ChangeTaskDueMutation.gql';
import DateTimeField from '@/components/FormFields/DateTimeField.vue';
import SINGLE_DUE from '@/graphql/task/query/GetSingleDueQuery.gql';

export default {
  name: 'ChangeTaskDueDate',
  components: { AbstractForm },
  data() {
    return {
      dialog: false,
      loading: false,
      query: SINGLE_DUE,
      mutation: CHANGE_DUE_DATE_MUTATION,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 9 },
                type: DateTimeField,
                model: { out: 'beginning', in: 'beginning' },
                props: { 'label': 'Początek', 'prepend-icon': 'mdi-calendar-clock', 'dateLabel': 'Początek' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 9 },
                type: DateTimeField,
                model: { out: 'date', in: 'due' },
                props: { 'label': 'Termin', 'prepend-icon': 'mdi-calendar-clock', 'dateLabel': 'Koniec' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 3 },
                type: VSwitch,
                initialData: () => false,
                model: { in: 'wholeDay', out: 'fullDay' },
                props: { label: 'Cały dzień', falseValue: false },
                rules: [],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.loading = true;
      this.$refs.changeDate.mutate();
    },
  },
};
</script>

<style scoped></style>
