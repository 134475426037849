<template>
  <span>
    <v-row>
      <v-col md="12">
        <v-autocomplete
          outlined
          item-value="id"
          item-text="name"
          :items="recipients"
          :loading="isLoading"
          v-model="internalValue"
          :disabled="disabled"
          v-bind="$props"
          persistent-hint
          :hint="hint"
          prepend-icon="mdi-auto-fix"
        />
      </v-col>
      <!--      <v-col mc="1">-->
      <!--        <AssignEntityToProceedingForm v-if="proceeding && proceeding.acl_edit" :proceeding-id="proceeding.id">-->
      <!--          <template v-slot:activator="{ openDialog }">-->
      <!--            <v-btn bottom color="grey" dark @click="openDialog">-->
      <!--              dodaj do postępowania-->
      <!--              <v-icon>mdi-plus</v-icon>-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--        </AssignEntityToProceedingForm>-->
      <!--      </v-col>-->
    </v-row>
  </span>
</template>
<script>
import GET_PROCEEDING_QUERY from '@/graphql/proceeding/query/ProceedingDetailsQuery.gql';

export default {
  name: 'RecipientSelectFromProceedingField',
  props: {
    value: [String, Array],
    label: String,
    multiple: Boolean,
    buildFunction: {
      type: Function,
      default(name, street, city, country, code) {
        return {
          'recipient.name': name,
          'recipient.address.street': street,
          'recipient.address.city': city,
          'recipient.address.country': country,
          'recipient.address.postCode': code,
        };
      },
    },
    disabled: Boolean,
    formValues: Object,
    rules: { type: Array },
    deletable: { type: Boolean, default: false },
    proceedingId: { type: String, default: undefined },
    onlyEntities: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    hint() {
      if (!this.proceeding) {
        return 'Najpierw wybierz postępowanie';
      }
      if (this.onlyEntities) {
        return '';
      }
      return 'Wybranie podmiotu spowoduje autouzupełnienie adresu';
    },
    recipientsMap() {
      if (!this.proceeding) {
        return {};
      }
      const temp = {};

      const build = this.buildFunction;
      // // push debtors
      // function build(name, street, city, country, code) {
      //   return this.buildFunction(name, street, city, country, code);
      // }

      for (const debtor of this.proceeding.debtors) {
        const name = `${debtor.name} ${debtor.surname}`;
        temp[debtor.id] = build(
          name,
          debtor.address.street,
          debtor.address.city,
          debtor.address.country,
          debtor.address.postCode,
        );
      }
      // push court
      if (this.proceeding.court) {
        temp[this.proceeding.court.id] = build(
          this.proceeding.court.name,
          this.proceeding.court.address.street,
          this.proceeding.court.address.city,
          this.proceeding.court.address.country,
          this.proceeding.court.address.postCode,
        );
      }
      // push entities
      for (const entity of this.proceeding.entities) {
        temp[entity.id] = build(
          entity.name,
          entity.address.street,
          entity.address.city,
          entity.address.country,
          entity.address.postCode,
        );
      }
      // push advisor
      if (this.proceeding.advisor) {
        const name = `${this.proceeding.advisor.firstName} ${this.proceeding.advisor.lastName}`;
        temp[this.proceeding.advisor.id] = build(
          name,
          // 'this.proceeding.advisor.address.street',
          // 'this.proceeding.advisor.address.city',
          // 'this.proceeding.advisor.address.country',
          // 'this.proceeding.advisor.address.postCode'
        );
      }
      return temp;
    },
    recipients() {
      if (!this.proceeding) {
        return [];
      }
      const temp = [];

      // push debtors
      function build(name, id, type, address) {
        return { name, id, type, address };
      }

      // push entities
      for (const entity of this.proceeding.entities) {
        temp.push(build(`Podmiot: ${entity.name}`, entity.id, 'entity', { 'recipient.name': entity.name }));
      }

      if (this.onlyEntities) {
        return temp;
      }

      for (const debtor of this.proceeding.debtors) {
        const name = `${debtor.name} ${debtor.surname}`;
        temp.push(build(`Dłużnik: ${name}`, debtor.id, 'debtor', { 'recipient.name': name }));
      }
      // push court
      if (this.proceeding.court) {
        temp.push(
          build(`Sąd: ${this.proceeding.court.name}`, this.proceeding.court.id, 'court', {
            'recipient.name': this.proceeding.court.name,
          }),
        );
      }

      // push advisor
      if (this.proceeding.advisor) {
        const name = `${this.proceeding.advisor.firstName} ${this.proceeding.advisor.lastName}`;
        temp.push(
          build(
            `Doradca: ${this.proceeding.advisor.firstName} ${this.proceeding.advisor.lastName}`,
            this.proceeding.advisor.id,
            'advisor',
            { 'recipient.name': name },
          ),
        );
      }
      return temp;
    },
    isLoading() {
      return this.$apollo.queries.proceeding.loading;
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
        this.$emit('updateFormValues', this.recipientsMap[newValue]);
      },
    },
  },
  apollo: {
    proceeding: {
      query: GET_PROCEEDING_QUERY,
      skip() {
        if (typeof this.proceedingId === 'string') {
          return false;
        }
        if (typeof this.formValues.proceedingId === 'string') {
          return false;
        }
        return true;
      },
      variables() {
        return {
          proceedingId: this.formValues.proceedingId || this.proceedingId,
        };
      },
      update(data) {
        return data.list.items[0];
      },
    },
  },
  watch: {
    proceeding(proceeding) {
      if (this.formValues.kind === 'OUTGOING_COURT') {
        this.internalValue = proceeding.court.id;
      }
    },
  },
};
</script>
