<template>
  <span>
    <span v-if="raw"> {{ debtor.name }} {{ debtor.surname }} </span>
    <EditLegalPersonDebtorForm v-else-if="withEdit && debtor.__typename === 'LegalPersonDebtor'" :debtor-id="debtor.id">
      <template v-slot:activator="{ openDialog }">
        <v-chip @click="openDialog" color="blue" dark v-bind="$props">
          <v-icon left>mdi-pencil</v-icon>{{ debtor.name }} {{ debtor.surname }}
        </v-chip>
      </template>
    </EditLegalPersonDebtorForm>
    <EditPrivatePersonDebtorForm
      v-else-if="withEdit && debtor.__typename === 'PrivatePersonDebtor'"
      :debtor-id="debtor.id"
    >
      <template v-slot:activator="{ openDialog }">
        <v-chip @click="openDialog" color="blue" dark v-bind="$props">
          <v-icon left>mdi-pencil</v-icon>{{ debtor.name }} {{ debtor.surname }}
        </v-chip>
      </template>
    </EditPrivatePersonDebtorForm>
    <v-chip v-else color="blue" dark v-bind="$props">{{ debtor.name }} {{ debtor.surname }}</v-chip>
  </span>
</template>
<script>
import EditLegalPersonDebtorForm from '@/components/Debtor/EditLegalPersonDebtorForm.vue';
import EditPrivatePersonDebtorForm from '@/components/Debtor/EditPrivatePersonDebtorForm.vue';

export default {
  name: 'DebtorChip',
  components: { EditPrivatePersonDebtorForm, EditLegalPersonDebtorForm },
  props: {
    debtor: {
      type: Object,
      required: true,
    },
    withEdit: { type: Boolean, default: false },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
    raw: { type: Boolean },
  },
};
</script>
