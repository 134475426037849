var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SetRelatedDocumentsForm',{attrs:{"proceeding-id":_vm.proceedingId,"entity-id":_vm.documentId,"query":_vm.query,"query-variables":function () { return ({ documentId: _vm.documentId }); },"query-update":function (data) { return data.list.items[0]; },"mutation":_vm.mutation,"mutation-error-mapper":function (ref) {
    var document = ref.data.document;

    return (document.hasOwnProperty('errors') ? document.errors : []);
},"mutation-variables":function (data) {
      data.documentId = _vm.documentId;
      return { input: data };
    }},on:{"done":function($event){return _vm.$emit('done')},"close":_vm.close}})}
var staticRenderFns = []

export { render, staticRenderFns }