<template>
  <span>
    <slot name="activator" v-bind:openDialog="openDialog"></slot>
    <v-dialog
      max-width="650px"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
      :value="dialog"
      @close="close"
      @click:outside="close"
    >
      <v-card>
        <div v-if="task">
          <v-toolbar dark :color="titleClass">
            <v-toolbar-title>
              <strong>
                {{ task.schedule.proceeding.signature }},
                <span v-for="(debtor, index) in task.schedule.proceeding.debtors" :key="index">
                  {{ debtor.name }}
                  {{ debtor.surname }},
                </span>
                {{ task.name }}
              </strong>
            </v-toolbar-title>
          </v-toolbar>
          <div class="v-card-text--padding">
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Status</strong></v-card-subtitle>
            <v-card-text>
              <TaskStatusChip :task="task" />
              <TaskStateTransitionButtons
                class="ml-5"
                :available-state-transitions="task.availableStateTransitions"
                :task-id="task.id"
              />
            </v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Termin</strong></v-card-subtitle>
            <v-card-text class="text--primary d-flex align-center">
              <div class="due">{{ formatDue(task) }} <br />termin {{ task.due | dateFrom | orEmpty }}</div>
              <ChangeTaskDueDate :task-id="task.id"></ChangeTaskDueDate>
            </v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Data ukończenia</strong></v-card-subtitle>
            <v-card-text class="text--primary"> {{ task.finishedAt | date | orDash }}</v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Opis</strong></v-card-subtitle>
            <v-card-text class="text--primary">{{ task.description | orEmpty }}</v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Osoba odpowiedzialna</strong></v-card-subtitle>
            <v-card-text class="text--primary">
              <AdvisorChip v-if="task.user" small :advisor="task.user"></AdvisorChip>
              <span v-else>brak</span></v-card-text
            >
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Postępowanie, dłużnik</strong></v-card-subtitle>
            <v-card-text class="text--primary">
              {{ task.schedule.proceeding.signature }},
              <DebtorListChip small :debtors="task.schedule.proceeding.debtors"></DebtorListChip>
            </v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0"><strong>Doradca w postępowaniu</strong></v-card-subtitle>
            <v-card-text class="text--primary">
              <AdvisorChip small :advisor="task.schedule.proceeding.advisor"></AdvisorChip>
            </v-card-text>
            <!-- -- -->

            <v-card-subtitle class="pb-0 pt-0"><strong>Dokumenty powiązane</strong></v-card-subtitle>
            <v-card-text class="text--primary">
              <SetTaskRelatedDocumentsForm
                :taskId="task.id"
                :proceedingId="task.schedule.proceeding.id"
                class="mr-4 mb-4"
              />
              <RelatedDocumentsChip class="mt-1" :documents="task.relatedDocuments" @click="documentId = task.id" />
            </v-card-text>
            <!-- -- -->
            <v-card-subtitle class="pb-0 pt-0" v-if="task.parentTask">
              <strong>Zadanie nadrzędne</strong>
            </v-card-subtitle>
            <v-card-text class="text--primary" v-if="task.parentTask">
              {{ task.parentTask.name }}
            </v-card-text>
            <!-- -- -->
          </div>
        </div>
        <div class="text-center" v-else>
          <v-progress-circular indeterminate color="blue-grey"></v-progress-circular>
        </div>
        <v-card-actions>
          <v-btn text color="secondary" v-if="task && task.acl_edit" @click="editTaskId = task.id">Edytuj</v-btn>
          <v-btn text color="secondary" @click="close">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
      <DocumentPreviewModal :document-id="documentId" @close="documentId = null" v-if="documentId" />
    </v-dialog>
    <EditTaskForm :task-id="editTaskId" v-if="editTaskId" @close="editTaskId = null" />
  </span>
</template>

<script>
import { mapClass } from '@/helpers/taskStatuses';
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import ChangeTaskDueDate from '@/components/Task/Form/ChangeTaskDueDate.vue';
import DebtorListChip from '@/components/Common/DebtorsListChip.vue';
import DocumentPreviewModal from '@/components/Document/DocumentPreviewModal.vue';
import EditTaskForm from '@/components/Task/Form/EditTaskForm.vue';
import SetTaskRelatedDocumentsForm from '@/components/Task/Form/SetTaskRelatedDocumentsForm.vue';
import TASK_DETAILS_QUERY from '@/graphql/task/query/TaskDetailsQuery.gql';
import TaskStateTransitionButtons from '@/components/Task/TaskStateTransitionButtons.vue';
import TaskStatusChip from '@/components/Common/Task/TaskStatusChip.vue';
import { formatDue } from '@/helpers/formatCalendarDue';

export default {
  name: 'TaskDetailsModal',
  components: {
    SetTaskRelatedDocumentsForm,
    TaskStatusChip,
    DebtorListChip,
    RelatedDocumentsChip: () => import('@/components/Common/RelatedDocumentsChip.vue'),
    AdvisorChip,
    TaskStateTransitionButtons,
    DocumentPreviewModal,
    EditTaskForm,
    ChangeTaskDueDate,
  },
  data() {
    return {
      dialog: this.forceOpenDialog,
      documentId: null,
      editTaskId: null,
    };
  },
  computed: {
    titleClass() {
      return mapClass(this.task.status);
    },
  },
  methods: {
    formatDue,
    mapClass,
    openDialog() {
      this.dialog = true;
    },
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
    forceOpenDialog: {
      type: Boolean,
      required: false,
    },
  },
  apollo: {
    task: {
      skip() {
        return !this.taskId || !this.dialog;
      },
      query: TASK_DETAILS_QUERY,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
};
</script>

<style scoped lang="sass">
.v-btn__content
  i
    margin-right: .75rem

.due
  margin-right: .75rem
</style>
