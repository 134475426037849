<template>
  <span v-if="document.status">
    <v-chip dark color="primary" v-bind="$props">{{ document.status | mapDocumentStatus }}</v-chip>
  </span>
</template>
<script>
export default {
  name: 'DocumentShipmentStatus',
  filters: {
    mapDocumentStatus(value) {
      switch (value) {
        case 'EDITION_IN_PROGRESS':
          return 'w opracowaniu';
        case 'FINISHED':
          return 'zakończone, niewysłane';
        case 'FINISHED_SENT_BY_MAIL':
          return 'zakończone, wysłane';
        // case 'FINISHED_SENT':
        //   return 'zakończone, wysłane';
        case 'FINISHED_DELIVERED_BY_HAND_UNCONFIRMED':
          return 'złożone osobiście, oczekuje na potwierdzenie';
        case 'FINISHED_DELIVERED_BY_HAND_CONFIRMED':
          return 'złożone osobiście, dodane potwierdzenie';
        default:
          return value;
      }
    },
    mapStampTypeToLabel(value) {
      switch (value) {
        case 'DeliveredByHandStamp':
          return 'dostarczone osobiście';
        case 'SentStamp':
        default:
          return 'dostarczone pocztą';
      }
    },
  },
  props: {
    small: Boolean,
    xSmall: Boolean,
    large: Boolean,
    document: {
      type: Object,
      required: true,
    },
  },
};
</script>
