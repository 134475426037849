<template>
  <v-container class="lighten-5 mb-6">
    <v-row gutters>
      <v-col>
        <ScheduleDetailsModal :scheduleId="scheduleId" v-if="scheduleId" />
        <GraphqlServerSideDataGrid
          @click:row="onRowClicked"
          title="Harmonogramy"
          :headers="headers"
          :sort-by="['id']"
          :query="QUERY"
          enable-search
        >
          <template v-slot:[`item.proceeding`]="{ item }">
            {{ item.proceeding.signature }} <DebtorListChip raw :debtors="item.proceeding.debtors" />
          </template>
          <template v-slot:[`item.advisor`]="{ item }">
            <AdvisorChip :advisor="item.proceeding.advisor" />
          </template>
          <template v-slot:[`item.court`]="{ item }">
            {{ item.proceeding.court.name }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.proceeding.status }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.proceeding.status | mapStatus }}
          </template>
          <template v-slot:[`item.taskInfo`]="{ item }">
            <TaskSummaryHelper :schedules="[item]" />
          </template>
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvisorChip from '@/components/Common/AdvisorChip.vue';
import DebtorListChip from '@/components/Common/DebtorsListChip.vue';
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import QUERY from '@/graphql/schedule/query/ScheduleListQuery.gql';
import ScheduleDetailsModal from '@/components/Schedule/ScheduleDetailsModal.vue';
import TaskSummaryHelper from '@/components/Task/Components/TaskSummaryHelper.vue';

export default {
  name: 'ScheduleListPage',
  props: {
    scheduleId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      QUERY,
      headers: [
        {
          text: 'Postepowanie',
          align: 'start',
          sortable: false,
          value: 'proceeding',
        },
        { text: 'Doradca', value: 'advisor', sortable: false },
        { text: 'Sąd', value: 'court', sortable: false },
        { text: 'Status sprawy', value: 'status' },
        { text: 'Informacje o zadaniach', value: 'taskInfo' },
        { text: 'Akcje', value: 'action', sortable: false },
      ],
    };
  },
  methods: {
    onRowClicked(row) {
      this.$router.push({ name: 'schedules/details', params: { id: row.id } });
    },
  },
  components: { ScheduleDetailsModal, TaskSummaryHelper, AdvisorChip, DebtorListChip, GraphqlServerSideDataGrid },
};
</script>
