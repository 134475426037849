<template>
  <DocumentPreviewModal :document-id="document.id" :max-embed-levels="maxEmbedLevels - 1">
    <template v-slot:activator="{ openDialog }">
      <v-btn v-bind="$props" @click="openDialog" class="mb-2" :disabled="maxEmbedLevels < 1">{{ document.name }}</v-btn>
    </template>
  </DocumentPreviewModal>
</template>

<script>
export default {
  name: 'RelatedDocumentChip',
  components: { DocumentPreviewModal: () => import('../Document/DocumentPreviewModal.vue') },
  props: {
    document: {
      type: Object,
      required: true,
    },
    maxEmbedLevels: {
      type: Number,
      required: false,
      default: () => 5,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>

<style lang="sass" scoped>
span.v-chip
  margin: .5rem 0 0 0
</style>
