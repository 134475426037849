<template>
  <div>
    <AbstractModalForm
      :key="key"
      @close="randomizeKey()"
      label="Dodaj dokument"
      :form-specification="formSpecification"
      :mutation="mutation"
      :mutation-error-mapper="(data) => (data.data.document.errors ? data.data.document.errors : [])"
      :refetch-queries="refetchQueries"
      :mutation-variables="
        (data) => {
          if (this.proceedingId) {
            data.proceedingId = this.proceedingId;
          }
          return { input: data };
        }
      "
      @done="$emit('done')"
    >
      <template v-slot:activator="{ openDialog, buttonProps }">
        <slot name="activator" v-bind:openDialog="openDialog" v-bind:buttonProps="buttonProps">
          <v-btn bottom color="pink" dark fab fixed- right @click="openDialog">
            <v-icon>mdi-paperclip</v-icon>
          </v-btn>
        </slot>
      </template>
    </AbstractModalForm>
  </div>
</template>

<script>
import { VFileInput, VSelect, VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import ADD_EXTERNAL_DOCUMENT from '@/graphql/document/mutation/AddExternalDocumentMutation.gql';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import DateField from '@/components/FormFields/DateField.vue';
import DebtorSelectField from '@/components/FormFields/DebtorSelectField.vue';
import ProceedingSelectField from '@/components/FormFields/Proceeding/ProceedingSelectField.vue';
import CardNumbersField from '@/components/FormFields/CardNumbersField.vue';

export default {
  name: 'AddExternalDocumentForm',
  components: { AbstractModalForm },
  data() {
    return {
      key: 1,
      mutation: ADD_EXTERNAL_DOCUMENT,
      formSpecification: {
        fields: [
          {
            columns: [
              // proceedingId: ID!
              // debtorId: ID!
              {
                cols: { md: 12 },
                skip: this.proceedingId !== null,
                type: ProceedingSelectField,
                model: { out: 'proceedingId' },
                props: { label: 'Postępowanie', activeOnly: true },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: DebtorSelectField,
                model: { out: 'debtorId' },
                props: {
                  label: 'Dłużnik',
                  proceedingId: this.proceedingId,
                  displayAddButtons: false,
                },
                rules: [isRequiredValidator],
              },
              // file: Upload!
              {
                cols: { md: 12 },
                type: VFileInput,
                model: { out: 'file' },
                props: {
                  label: 'Plik',
                  accept: 'application/pdf,.pdf',
                },
                rules: [isRequiredValidator],
              },
              // kind: DocumentKindEnum!
              {
                cols: { md: 12 },
                type: VSelect,
                model: { out: 'kind' },
                props: {
                  'label': 'Rodzaj dokumentu',
                  'prepend-icon': 'mdi-identifier',
                  'items': [
                    {
                      value: 'INCOMING_COURT',
                      text: 'Przychodząca: sąd',
                    },
                    {
                      value: 'INCOMING_CREDITOR',
                      text: 'Przychodząca: wierzyciel',
                    },
                    {
                      value: 'INCOMING_OTHER',
                      text: 'Przychodząca: inna',
                    },
                    {
                      value: 'OUTGOING_COURT',
                      text: 'Wychodząca: sąd',
                    },
                    {
                      value: 'OUTGOING_CREDITOR',
                      text: 'Wychodząca: wierzyciel',
                    },
                    {
                      value: 'OUTGOING_OTHER',
                      text: 'Wychodząca: inna',
                    },
                  ],
                },
                rules: [isRequiredValidator],
              },
              // receivedAt: DateTime!
              {
                cols: { md: 6 },
                type: DateField,
                initialData: () => new Date().toISOString(),
                model: { out: 'receivedAt' },
                props: { label: 'Termin doręczenia/nadania' },
                rules: [isRequiredValidator],
              },
              // name: String!
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'Nazwa dokumentu' },
                rules: [isRequiredValidator],
              },
              // name: String!
              {
                cols: { md: 12 },
                type: CardNumbersField,
                model: { out: 'cardNumbers' },
                props: () => {
                  return {
                    label: 'Numery kart',
                    proceedingId: this.proceedingId,
                  };
                },
                rules: [],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    randomizeKey() {
      this.key += 1;
    },
  },
  props: {
    refetchQueries: {
      type: Array,
      default: () => [],
    },
    proceedingId: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped></style>
