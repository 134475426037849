var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5 mb-6"},[_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed-":"","right":""},on:{"click":function($event){return _vm.$router.push({ name: 'document-templates' })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-row',{attrs:{"gutters":""}},[_c('v-col',[_c('GraphqlServerSideDataGrid',{attrs:{"enable-search":"","forced-filters":[
          {
            field: 'type',
            value: 'writing',
            type: 'EQ',
          } ],"title":"Pisma","headers":_vm.headers,"query":_vm.query,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticClass:"ml-5 mb-2",attrs:{"disabled":_vm.selected.length < 1,"small":""},on:{"click":_vm.downloadPdf}},[_vm._v("Pobierz pdf")]),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"disabled":_vm.selected.length < 1,"small":""},on:{"click":_vm.print}},[_vm._v("Drukuj")])]},proxy:true},{key:"item.kind",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","color":_vm._f("documentKindClass")(item.kind)}},[_vm._v(" "+_vm._s(_vm._f("documentKindName")(item.kind))+" ")])]}},{key:"item.cardNumbers",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("orEmpty")(item.cardNumbers.from))+" - "+_vm._s(_vm._f("orEmpty")(item.cardNumbers.to))+" ")]}},{key:"item.proceeding",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.proceeding.signature)+" "+_vm._s(item.proceeding.debtors.map(function (debtor) { return ((debtor.name) + " " + (debtor.surname)); }).join(', '))+" ")]}},{key:"item.advisor",fn:function(ref){
        var item = ref.item;
return [_c('AdvisorChip',{attrs:{"small":"","advisor":item.proceeding.advisor}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.senderReceiver",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getSenderOrReceiverPrefix")(item.kind))+" "),(item.recipient)?_c('span',[_vm._v(_vm._s(item.recipient.name))]):(item.recipientSender)?_c('span',[_vm._v(_vm._s(_vm._f("orEmpty")(item.recipientSender.name)))]):_vm._e()]}},{key:"item.state",fn:function(ref){
        var item = ref.item;
return [_c('DocumentShipmentStatus',{attrs:{"document":item}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('DocumentPreviewModal',{attrs:{"document-id":item.id,"max-embed-levels":3},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var openDialog = ref.openDialog;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","outlined":""},on:{"click":openDialog}},[_vm._v("pokaż")])]}}],null,true)}),(item.acl_edit)?_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.$router.push({ name: 'documents/edit', params: { id: item.id } })}}},[_vm._v("edytuj ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }