<template>
  <div>
    <v-dialog
      max-width="800px"
      persistent
      :value="dialog"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="orange">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodaj powiązane zadania</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save" :loading="loading">Zapisz</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <AbstractForm
            ref="addForm"
            :form-specification="formSpecification"
            :entity-id="entityId"
            :mutation="mutation"
            :mutation-variables="mutationVariables"
            :mutation-error-mapper="mutationErrorMapper"
            :query="query"
            :query-variables="queryVariables"
            :query-update="queryUpdate"
            @done="
              dialog = false;
              $emit('done');
            "
            @loading="handleLoading"
          ></AbstractForm>
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="activator" v-bind:openDialog="openDialog">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" fab x-small dark v-bind="attrs" v-on="on" @click="openDialog">
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </template>
        <span>Powiąż zadanie</span>
      </v-tooltip>
    </slot>
  </div>
</template>
<script>
import AbstractForm from '@/components/Form/AbstractForm.vue';
import TasksSelectField from '@/components/FormFields/TasksSelectField.vue';

export default {
  name: 'SetRelatedTasksForm',
  components: { AbstractForm },
  data() {
    return {
      loading: false,
      dialog: false,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: TasksSelectField,
                model: { out: 'taskIds', in: 'relatedTasks' },
                // map array of objects to array of strings
                inputModifier: (input) => (input === null ? [] : input.map((relatedTasks) => relatedTasks.id)),
                props: {
                  proceedingId: this.proceedingId,
                },
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    entityId: { type: String, required: true },
    query: { type: Object },
    mutation: { type: Object },
    mutationVariables: { type: Function },
    queryVariables: { type: Function },
    mutationErrorMapper: { type: Function },
    queryUpdate: { type: Function },
  },
  methods: {
    handleLoading(value) {
      this.loading = value;
    },
    openDialog() {
      this.dialog = true;
    },
    close(proceeding) {
      this.$emit('close', proceeding);
    },
    save() {
      this.$refs.addForm.mutate();
    },
  },
};
</script>
