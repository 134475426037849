<template>
  <v-chip v-bind="$props" v-if="advisor"> {{ advisor.lastName }} {{ advisor.firstName }} </v-chip>
</template>
<script>
export default {
  name: 'AdvisorChip',
  props: {
    advisor: {
      type: Object || null,
      required: true,
    },
    xSmall: { type: Boolean },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>
