<template>
  <div>
    <AbstractModalForm
      :button-props="buttonProps"
      label="Edytuj dane"
      ref="form"
      :form-specification="formSpecification"
      :mutation="MUTATION"
      :mutation-error-mapper="(data) => (data.data.document.errors ? data.data.document.errors : [])"
      :refetch-queries="refetchQueries"
      :mutation-variables="
        (data) => {
          data.externalDocumentId = documentId;
          delete data.entityId;
          return { input: data };
        }
      "
      :entity-id="documentId"
      :query="QUERY"
      :query-variables="() => ({ documentId })"
      :query-update="(data) => data.list.items[0]"
      @done="
        $emit('done');
        showModal = false;
      "
    />
  </div>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import DateField from '@/components/FormFields/DateField.vue';
import MUTATION from '@/graphql/document/mutation/EditExternalDocumentMutation.gql';
import QUERY from '@/graphql/document/query/GetOneDocumentQuery.gql';
import CardNumbersField from '@/components/FormFields/CardNumbersField.vue';
import RecipientSelectFromProceedingField from '@/components/FormFields/Document/RecipientSelectFromProceedingField.vue';
import DocumentTypeSelectField from '@/components/FormFields/Document/DocumentTypeSelectField.vue';

export default {
  name: 'EditExternalDocumentForm',
  components: { AbstractModalForm },
  data() {
    return {
      MUTATION,
      QUERY,
      formSpecification: {
        fields: [
          {
            columns: [
              // name: String!
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'Nazwa dokumentu' },
                rules: [isRequiredValidator],
              },
              // kind: DocumentKindEnum!
              {
                cols: { md: 12 },
                type: DocumentTypeSelectField,
                boundListeners: {
                  input({ form }) {
                    // return actual listener
                    return (value) => {
                      if (!form.entity) {
                        return;
                      }
                      const { court } = form.entity.proceeding;
                      switch (value) {
                        case 'INCOMING_COURT':
                        case 'OUTGOING_COURT':
                          form.updateFormValues({
                            'recipientSender.address.postCode': court.address.postCode,
                            'recipientSender.address.country': court.address.country,
                            'recipientSender.address.street': court.address.street,
                            'recipientSender.address.city': court.address.city,
                            'recipientSender.name': court.name,
                          });
                          break;
                        default:
                          break;
                      }
                    };
                  },
                },
                model: { out: 'kind' },
                props: {
                  'label': 'Rodzaj dokumentu',
                  'prepend-icon': 'mdi-identifier',
                },
                rules: [isRequiredValidator],
              },
              // receivedAt: DateTime!
              {
                cols: { md: 6 },
                type: DateField,
                initialData: () => new Date().toISOString(),
                model: { out: 'receivedAt' },
                props: { label: 'Termin doręczenia/nadania' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: CardNumbersField,
                model: { out: 'cardNumbers' },
                props: { label: 'Numery kart' },
                rules: [],
              },
              {
                cols: { md: 12 },
                skip: ({ model }) => {
                  if (!model.kind) {
                    return true; // skip if kind is not selected
                  }
                  // if kind is equal to INCOMING_CREDITOR or OUTGOING_CREDITOR
                  switch (model.kind) {
                    case 'INCOMING_CREDITOR':
                    case 'OUTGOING_CREDITOR':
                      return false; // dont skip
                    default:
                      return true; // by default - skip
                  }
                },
                type: RecipientSelectFromProceedingField,
                model: { out: 'entityId' },
                props: {
                  label: 'Wybierz podmiot',
                  onlyEntities: true,
                  proceedingId: this.proceedingId,
                  // this function maps data from entity into event data
                  // ( see src/components/FormFields/Document/RecipientSelectFromProceedingField.vue:65 )
                  buildFunction: (name, street, city, country, code) => {
                    return {
                      'recipientSender.name': name,
                      'recipientSender.address.street': street,
                      'recipientSender.address.city': city,
                      'recipientSender.address.country': country,
                      'recipientSender.address.postCode': code,
                    };
                  },
                },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'recipientSender.name' },
                props: { label: 'Nazwa odbiorcy / nadawcy' },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'recipientSender.address.street' },
                props: { label: 'Adres' },
                rules: [],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'recipientSender.address.postCode' },
                props: { label: 'Kod pocztowy' },
                rules: [],
              },
              {
                cols: { md: 6 },
                type: VTextField,
                model: { out: 'recipientSender.address.city' },
                props: { label: 'Miasto' },
                rules: [],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    refetchQueries: {
      type: Array,
      default: () => [],
    },
    documentId: {
      type: String,
      required: true,
    },
    proceedingId: {
      type: String,
      required: true,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: () => {
        return { 'x-small': true, 'outlined': true };
      },
    },
  },
};
</script>
