var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","persistent":"","value":!!_vm.noteId,"fullscreen":false,"scrollable":false,"transition":"dialog-bottom-transition"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Edytuj notatkę")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","loading":_vm.$apollo.loading},on:{"click":_vm.save}},[_vm._v("Zapisz")])],1)],1),_c('v-container',{staticStyle:{"overflow":"auto"}},[_c('AbstractForm',{ref:"addForm",attrs:{"form-specification":_vm.formSpecification,"entity-id":_vm.noteId,"mutation":_vm.editMutation,"mutation-variables":function (data, entityId) {
            data.noteId = entityId;
            return { input: data };
          },"mutation-error-mapper":function (ref) {
                    var note = ref.data.note;

                    return (note.hasOwnProperty('errors') ? note.errors : []);
},"query":_vm.noteQuery,"query-variables":function (noteId) { return ({ noteId: noteId }); },"query-update":function (data) { return data.note; }},on:{"done":function($event){return _vm.$emit('close')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }