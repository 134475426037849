<template>
  <FilterBaseTemplate @propagate="propagate()" :has-value="!!internalValue">
    <v-autocomplete
      v-model="internalValue"
      item-text="label"
      item-value="id"
      chips
      clearable
      small-chips
      :items="itemsComputed"
    />
  </FilterBaseTemplate>
</template>

<script>
import QUERY from '@/graphql/user/query/ListUsersQuery.gql';
import FilterBaseTemplate from '@/components/Filters/FilterBaseTemplate.vue';
import FilterPropagateEventMixin from '@/components/Filters/FilterPropagateEventMixin';

export default {
  name: 'FilterByAdvisor',
  components: { FilterBaseTemplate },
  mixins: [FilterPropagateEventMixin],
  props: {
    ...FilterPropagateEventMixin.props,
  },
  data() {
    return {
      internalValue: null,
    };
  },
  computed: {
    itemsComputed() {
      if (!this.items) {
        return [];
      }
      return this.items.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.label = `${item.firstName} ${item.lastName}`;
        return item;
      });
    },
  },

  methods: {
    propagate() {
      this.emitPropagateFromHeader('EQ', this.internalValue);
    },
  },
  apollo: {
    items: {
      query: QUERY,
      update: (data) => data.list.items,
    },
  },
};
</script>
