<template>
  <span>
    <v-select
      :items="items"
      outlined
      v-model="myInternalValue"
      :error="shouldValidate && errorBucket.length > 0"
      v-bind="$props"
    ></v-select>
    <VMessages v-if="shouldValidate" :value="errorBucket" color="error" />
  </span>
</template>
<script>
import { VInput, VMessages } from 'vuetify/lib';

export default {
  extends: VInput,
  name: 'DocumentTypeSelectField',
  components: { VMessages },
  data() {
    return {
      internalItems: [
        {
          value: 'INCOMING_COURT',
          text: 'Przychodząca: sąd',
          outgoing: false,
        },
        {
          value: 'INCOMING_CREDITOR',
          text: 'Przychodząca: wierzyciel',
          outgoing: false,
        },
        {
          value: 'INCOMING_OTHER',
          text: 'Przychodząca: inna',
          outgoing: false,
        },
        {
          value: 'OUTGOING_COURT',
          text: 'Wychodząca: sąd',
          outgoing: true,
        },
        {
          value: 'OUTGOING_CREDITOR',
          text: 'Wychodząca: wierzyciel',
          outgoing: true,
        },
        {
          value: 'OUTGOING_OTHER',
          text: 'Wychodząca: inna',
          outgoing: true,
        },
      ],
    };
  },
  props: {
    value: String,
    label: String,
    loading: { type: Boolean, default: false },
    outgoingOnly: { type: Boolean, default: false },
  },
  computed: {
    items() {
      if (this.outgoingOnly) {
        return this.internalItems.filter((item) => item.outgoing);
      }
      return this.internalItems;
    },
    globalLoading() {
      return this.$apollo.queries.documents.loading || this.loading;
    },
    myInternalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
