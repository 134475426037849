<template>
  <div class="recipient-address-chip">
    <p>
      <strong>{{ recipient.name }}</strong>
    </p>
    <AddressChip :address="recipient.address" />
  </div>
</template>
<script>
import AddressChip from '@/components/Common/AddressChip.vue';

export default {
  name: 'RecipientAddressChip',
  components: { AddressChip },
  props: {
    recipient: {
      type: Object || null,
      required: true,
    },
  },
};
</script>
<style scoped>
.recipient-address-chip p {
  margin-bottom: 0px;
}
</style>
