// eslint-disable-next-line
const mapDue = (status, due, finishedAt) => {
  if (status === 'FINISHED') {
    return '';
  }
  const currentDate = new Date();
  const dueDate = Date.parse(due);

  if (currentDate >= dueDate) {
    return ', opóźnione';
  }

  return ', w terminie';
};

export const mapStatus = (status) => {
  switch (status) {
    case 'NEW':
      return 'Nowy';
    case 'IN_PROGRESS':
      return 'W trakcie';
    case 'FINISHED':
      return 'Ukonczony';
    default:
      return status;
  }
};
export const mapClass = (status) => {
  switch (status) {
    case 'NEW':
      return 'indigo';
    case 'IN_PROGRESS':
      return 'red';
    case 'FINISHED':
      return 'grey';
    default:
      return 'red';
  }
};

const taskStatuses = (due, finishedAt, status) => {
  return { name: `${mapStatus(status)} ${mapDue(status, due, finishedAt)}`, class: mapClass(status) };
};
export const taskStatusName = (due, finishedAt, status) => {
  return taskStatuses(due, finishedAt, status).name;
};
export const taskClass = (due, finishedAt, status) => {
  return taskStatuses(due, finishedAt, status).class;
};
