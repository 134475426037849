var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.debug)?_c('pre',[_vm._v("    Options:\n    "+_vm._s(_vm.options)+"\n    ---------------\n    Query vars:\n    "+_vm._s(_vm.queryVariables)+"\n    ---------------\n    Gql filters:\n    "+_vm._s(_vm.graphqlFilters)+"\n    ---------------\n    Gql filters simplified:\n    "+_vm._s(_vm.graphqlFiltersSimplified)+"\n  ")]):_vm._e(),_c('ServerSideDataGrid',_vm._b({ref:"dataTable",attrs:{"itemsPerPage":_vm.options.itemsPerPage,"serverItemsLength":_vm.serverItemsLength,"loading":_vm.$apollo.queries.items.loading,"headers":_vm.headers,"items":_vm.items,"options":_vm.options},on:{"displayItem":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'displayItem' ].concat( argsArray ))},"update:options":_vm.onUpdateOptions,"click:row":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'click:row' ].concat( argsArray ))},"refetch":_vm.refetch,"search":_vm.handleSearchChange,"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'input' ].concat( argsArray ))}},scopedSlots:_vm._u([_vm._l((_vm.graphqlHeaderFilter),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('div',{key:("header." + (header.value)),staticClass:"float-left mr-1"},[_vm._v(_vm._s(header.text))]),_c('div',{key:("filter_body." + (header.value)),staticClass:"float-left"},[_c(header.graphqlFilter.component,_vm._b({key:header.value,ref:("filter_" + (header.value)),refInFor:true,tag:"component",attrs:{"header":header},on:{"applyFilter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.applyFilter.apply(void 0, [ header ].concat( argsArray ))},"clearFilter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.clearFilter.apply(void 0, [ header ].concat( argsArray ))}}},'component',header.graphqlFilter.props,false))],1)]},proxy:true}}),_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'ServerSideDataGrid',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }