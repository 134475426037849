<template>
  <span>
    <v-autocomplete
      outlined
      :items="filteredProceedings"
      item-value="id"
      item-text="signature"
      :loading="$apollo.queries.proceedings.loading"
      v-model="internalValue"
      v-bind="$props"
      prepend-icon="mdi-identifier"
    >
      <template slot="selection" slot-scope="data">
        <v-chip>{{ data.item.signature }}</v-chip>
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.signature }}
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import ACTIVE_PROCEEDING_QUERY from '@/graphql/proceeding/query/ListActiveProceedingsForFormQuery.gql';
import PROCEEDING_QUERY from '@/graphql/proceeding/query/ListProceedingsForFormQuery.gql';

export default {
  name: 'ProceedingSelectField',
  props: {
    activeOnly: {
      type: Boolean,
      default: () => false,
    },
    value: String,
    label: String,
    filterByCourt: { type: Boolean, default: false },
    filterByAdvisor: { type: Boolean, default: false },
    rules: { type: Array },
    formValues: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    filteredProceedings() {
      if (!this.proceedings) {
        return [];
      }
      let data = this.proceedings;
      // display only proceedings for that debtor
      if (this.formValues && this.formValues.debtorId) {
        data = data.filter((proceeding) => {
          return (
            proceeding.debtors.filter((debtor) => {
              return debtor.id === this.formValues.debtorId;
            }).length > 0
          );
        });
      }

      if (this.formValues && this.formValues.courtId && this.filterByCourt) {
        data = data.filter((proceeding) => proceeding.court.id === this.formValues.courtId);
      }
      if (this.formValues && this.formValues.advisorId && this.filterByAdvisor) {
        data = data.filter((proceeding) => proceeding.advisor.id === this.formValues.advisorId);
      }

      return data;
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'formValues.debtorId': function () {
      const entities = this.filteredProceedings;
      if (entities.length === 1) {
        this.$emit('input', entities[0].id);
      }
    },
  },
  apollo: {
    proceedings: {
      query() {
        if (this.activeOnly) {
          return ACTIVE_PROCEEDING_QUERY;
        }
        return PROCEEDING_QUERY;
      },
      update(data) {
        return data.list.items;
      },
    },
  },
};
</script>
