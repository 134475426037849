<template>
  <span>
    <DebtorChip :with-edit="withEdit" :debtor="debtor" v-for="debtor in debtors" v-bind="$props" :key="debtor.id" />
  </span>
</template>
<script>
import DebtorChip from '@/components/Common/DebtorChip.vue';

export default {
  name: 'DebtorListChip',
  components: { DebtorChip },
  props: {
    debtors: {
      type: Array,
      required: true,
    },
    xSmall: { type: Boolean },
    raw: { type: Boolean, default: false },
    withEdit: { type: Boolean, default: false },
    small: { type: Boolean },
    medium: { type: Boolean },
    large: { type: Boolean },
  },
};
</script>
