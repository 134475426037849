<template>
  <v-dialog
    max-width="800px"
    persistent
    :value="!!noteId"
    :fullscreen="false"
    :scrollable="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edytuj notatkę</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save" :loading="$apollo.loading">Zapisz</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container style="overflow: auto">
        <AbstractForm
          ref="addForm"
          :form-specification="formSpecification"
          :entity-id="noteId"
          :mutation="editMutation"
          :mutation-variables="
            (data, entityId) => {
              data.noteId = entityId;
              return { input: data };
            }
          "
          :mutation-error-mapper="({ data: { note } }) => (note.hasOwnProperty('errors') ? note.errors : [])"
          :query="noteQuery"
          :query-variables="(noteId) => ({ noteId })"
          :query-update="(data) => data.note"
          @done="$emit('close')"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { VTextField, VTextarea } from 'vuetify/lib';
import { isRequiredValidator, noteContentLengthValidator, noteTitleLengthValidator } from '@/helpers/validators';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import EDIT_NOTE_MUTATION from '@/graphql/note/mutation/EditProceedingNoteMutation.gql';

import BASIC_NOTE_QUERY from '@/graphql/note/query/GetNoteQuery.gql';

export default {
  name: 'EditProceedingNote',
  components: { AbstractForm },
  data() {
    return {
      noteQuery: BASIC_NOTE_QUERY,
      editMutation: EDIT_NOTE_MUTATION,
      editFormLoading: false,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'title' },
                props: { 'label': 'Tytuł', 'prepend-icon': 'mdi-format-text' },
                rules: [isRequiredValidator, noteTitleLengthValidator],
              },
              {
                cols: { md: 12 },
                type: VTextarea,
                model: { out: 'content' },
                props: { 'label': 'Treść', 'prepend-icon': 'mdi-text-box-outline' },
                rules: [isRequiredValidator, noteContentLengthValidator],
              },
            ],
          },
          // TODO:
          // {
          //   columns: [
          //     {
          //       cols: { md: 12 },
          //       type: VTextField,
          //       model: { out: 'relatedTasks' },
          //       props: { 'label': 'Powiązane zadania', 'prepend-icon': 'mdi-format-list-text' },
          //       rules: [isRequiredValidator],
          //     },
          //     {
          //       cols: { md: 12 },
          //       type: ProceedingRelatedDocumentsSelectField,
          //       model: { out: 'relatedDocuments' },
          //       props: {
          //         'label': 'Powiązane dokumenty',
          //         'prepend-icon': 'mdi-file-document-multiple-outline',
          //         'proceedingId': this.proceedingId,
          //       },
          //       rules: [isRequiredValidator],
          //     },
          //   ],
          // },
        ],
      },
    };
  },
  props: {
    noteId: {
      type: String,
      required: false,
    },
    proceedingId: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$refs.addForm.mutate();
    },
  },
};
</script>
