<template>
  <SetRelatedDocumentsForm
    :proceeding-id="proceedingId"
    :entity-id="noteId"
    :query="query"
    :query-variables="() => ({ noteId })"
    :query-update="(data) => data.note"
    :mutation="mutation"
    :mutation-error-mapper="({ data: { note } }) => (note.hasOwnProperty('errors') ? note.errors : [])"
    :mutation-variables="
      (data, entityId) => {
        data.noteId = entityId;
        return { input: data };
      }
    "
    @close="close"
    @done="$emit('done')"
  />
</template>
<script>
import BASIC_NOTE_QUERY from '@/graphql/note/query/GetNoteQuery.gql';
import SET_RELATED_TASKS_MUTATION from '@/graphql/note/mutation/SetNoteRelatedDocumentsMutation.gql';
import SetRelatedDocumentsForm from '@/components/Common/Form/SetRelatedDocumentsForm.vue';

export default {
  name: 'SetNoteRelatedDocumentsForm',
  components: { SetRelatedDocumentsForm },
  data() {
    return {
      mutation: SET_RELATED_TASKS_MUTATION,
      query: BASIC_NOTE_QUERY,
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    noteId: { type: String, required: true },
  },
  methods: {
    close(proceeding) {
      if (proceeding) {
        this.$router.push({ name: 'proceedings/details', params: { id: proceeding.id } });
        return;
      }
      this.$router.push({ name: 'proceedings' });
    },
  },
};
</script>
