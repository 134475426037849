<template>
  <div v-html="htmlValue" />
</template>
<script>
import markdown2html from '@ckeditor/ckeditor5-markdown-gfm/src/markdown2html/markdown2html';

export default {
  name: 'MarkdownParser',
  props: {
    value: String,
  },
  methods: {
    markdown2html,
  },
  computed: {
    htmlValue() {
      return markdown2html(this.value);
    },
  },
};
</script>
