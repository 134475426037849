var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SetRelatedDocumentsForm',{attrs:{"proceeding-id":_vm.proceedingId,"entity-id":_vm.noteId,"query":_vm.query,"query-variables":function () { return ({ noteId: _vm.noteId }); },"query-update":function (data) { return data.note; },"mutation":_vm.mutation,"mutation-error-mapper":function (ref) {
    var note = ref.data.note;

    return (note.hasOwnProperty('errors') ? note.errors : []);
},"mutation-variables":function (data, entityId) {
      data.noteId = entityId;
      return { input: data };
    }},on:{"close":_vm.close,"done":function($event){return _vm.$emit('done')}}})}
var staticRenderFns = []

export { render, staticRenderFns }