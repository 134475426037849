<template>
  <v-container class="lighten-5 mb-6">
    <v-row gutters>
      <v-col>
        <DataGrid title="Harmonogram" :headers="taskHeaders" :items="toChange"></DataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// TODO:FIX: change query source
import DataGrid from '@/components/Common/DataGrid.vue';
import TASK_LIST_QUERY from '@/graphql/task/query/TaskListQuery.gql';

export default {
  name: 'TimeTablePage',
  components: { DataGrid },
  data() {
    return {
      taskHeaders: [
        {
          text: 'Postępowanie',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        { text: 'Doradca', value: 'name' },
        { text: 'Sąd', value: 'name' },
        { text: 'Status sprawy', value: 'name' },
        { text: 'Informacje o zadaniach', value: 'name' },
      ],
    };
  },
  apollo: {
    toChange: {
      // TODO:FIX: change name
      // TODO:FIX: change query source
      query: TASK_LIST_QUERY,
      update: (data) => {
        return data.listTasks.items;
      },
    },
  },
};
</script>
