<template>
  <span>
    <slot name="activator" v-bind:openDialog="openDialog"></slot>
    <v-dialog
      max-width="500px"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
      :value="dialog"
      @close="close"
      @click:outside="close"
    >
      <v-card>
        <NoteCard :note="note" :proceeding-id="proceedingId"></NoteCard>
        <v-card-actions class="d-flex justify-end">
          <v-btn text color="secondary" @click="close">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import NoteCard from '@/components/Note/NoteCard.vue';

export default {
  name: 'RelatedNoteModal',
  components: { NoteCard },
  data() {
    return {
      dialog: false,
      documentId: null,
      editTaskId: null,
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    note: {
      type: Object || null,
      required: true,
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
