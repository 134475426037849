<template>
  <span>
    <slot name="activator" v-bind:openDialog="openDialog"></slot>
    <v-dialog
      max-width="800px"
      persistent
      :value="showModal"
      :fullscreen="false"
      :scrollable="false"
      transition="dialog-bottom-transition"
      @close="this.$emit('close')"
    >
      <v-card>
        <v-toolbar dark color="purple">
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodaj zadanie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save" :loading="$apollo.loading">Zapisz</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container style="overflow: auto">
          <AbstractForm
            ref="addForm"
            :form-specification="formSpecification"
            :mutation="mutation"
            :mutation-error-mapper="(data) => (data.data.task.errors ? data.data.task.errors : [])"
            :mutationVariables="
              (data) => {
                if (proceedingId) {
                  data.proceedingId = proceedingId;
                }
                delete data.debtorId;
                return { input: data };
              }
            "
            @done="onDone"
            @updateStore="updateStore"
          >
          </AbstractForm>
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { VTextField, VTextarea } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import ADD_NEW_TASK from '@/graphql/task/mutation/AddTaskMutation.gql';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import AdvisorSelectField from '@/components/FormFields/AdvisorSelectField.vue';
import DateTimeField from '@/components/FormFields/DateTimeField.vue';
import DebtorSelectField from '@/components/FormFields/DebtorSelectField.vue';
import PROCEEDING_DETAILS_QUERY from '@/graphql/proceeding/query/ProceedingDetailsQuery.gql';
import ProceedingSelectField from '@/components/FormFields/Proceeding/ProceedingSelectField.vue';

export default {
  name: 'AddTaskForm',
  components: {
    AbstractForm,
  },
  data() {
    return {
      showModal: false,
      mutation: ADD_NEW_TASK,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: {
                  'label': 'Tytuł',
                  'prepend-icon': 'mdi-format-title',
                },
                rules: [isRequiredValidator],
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 5 },
                type: DateTimeField,
                initialData: () => new Date().toISOString(),
                model: { out: 'due' },
                props: { 'label': 'Data', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 7 },
                type: AdvisorSelectField,
                model: { out: 'userId' },
                props: { 'label': 'Osoba odpowiedzialna', 'prepend-icon': 'mdi-account-outline', 'deletable': true },
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextarea,
                model: { out: 'description' },
                props: { 'label': 'Treść', 'prepend-icon': 'mdi-text-box-outline' },
                rules: [isRequiredValidator],
              },
            ],
          },
          {
            columns: [
              {
                cols: { md: 6 },
                type: ProceedingSelectField,
                skip: () => !!this.proceedingId,
                model: { out: 'proceedingId' },
                props: { 'label': 'Id postępowania', 'prepend-icon': 'mdi-identifier' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 6 },
                type: DebtorSelectField,
                skip: () => !!this.proceedingId,
                model: { out: 'debtorId' },
                props: { 'label': 'Dłużnik', 'prepend-icon': 'mdi-account-outline', 'displayAddButtons': false },
                rules: [],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    addTaskDialog: {
      type: Boolean,
      default: () => false,
    },
    proceedingId: {
      type: String,
      required: false,
      default: () => undefined,
    },
  },
  methods: {
    openDialog() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.$emit('close');
    },
    save() {
      this.$refs.addForm.mutate();
    },
    onDone() {
      this.$emit('refetch');
      this.showModal = false;
    },
    updateStore({ store, serverData, hasServerSideErrors }) {
      if (hasServerSideErrors) {
        return;
      }

      let proceeding;
      const proceedingId = serverData.data.task.schedule.proceeding.id;
      try {
        proceeding = store.readQuery({
          query: PROCEEDING_DETAILS_QUERY,
          variables: { proceedingId },
        });
      } catch (e) {
        // this is not an error, that means user has not yet went to that proceeding and
        // that it's not cached
        return;
      }

      // inject task into proceeding tasks
      proceeding.list.items[0].schedules[0].tasks = [
        serverData.data.task,
        ...proceeding.list.items[0].schedules[0].tasks,
      ];

      // write proceeding back to query
      store.writeQuery({
        query: PROCEEDING_DETAILS_QUERY,
        variables: { proceedingId },
        data: proceeding,
      });
    },
  },
};
</script>

<style scoped></style>
