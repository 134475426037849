<template>
  <FilterBaseTemplate :has-value="!!internalValue" @propagate="propagate()">
    <v-select v-model="internalValue" :items="items" :label="header.text" clearable />
  </FilterBaseTemplate>
</template>

<script>
import FilterBaseTemplate from '@/components/Filters/FilterBaseTemplate.vue';

export default {
  name: 'FilterBySelectField',
  components: { FilterBaseTemplate },
  data() {
    return {
      internalValue: null,
    };
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    propagate() {
      if (!this.internalValue) {
        this.$emit('clearFilter', [{ field: this.header.value }]);
        return;
      }
      this.$emit('applyFilter', [
        {
          field: this.header.value,
          type: 'EQ',
          value: this.internalValue,
        },
      ]);
    },
  },
};
</script>
