var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modal)?_c('AbstractModalForm',{ref:"editModalForm",attrs:{"button-props":_vm.buttonProps,"label":"Edytuj Dane","form-specification":_vm.formSpecification,"mutation":_vm.MUTATION,"mutation-error-mapper":function (data) { return (data.data.document.errors ? data.data.document.errors : []); },"refetch-queries":_vm.refetchQueries,"mutation-variables":function (data) {
        delete data.entityId;
        data.documentId = _vm.documentId;
        return { input: data };
      },"entity-id":_vm.documentId,"query":_vm.QUERY,"query-variables":function () { return ({ documentId: _vm.documentId }); },"query-update":function (data) { return data.list.items[0]; }},on:{"done":function($event){_vm.$emit('done');
      _vm.showModal = false;}}}):_c('AbstractForm',{ref:"editForm",attrs:{"form-specification":_vm.formSpecification,"mutation":_vm.MUTATION,"mutation-error-mapper":function (data) { return (data.data.document.errors ? data.data.document.errors : []); },"refetch-queries":_vm.refetchQueries,"mutation-variables":function (data) {
        delete data.entityId;
        data.documentId = _vm.documentId;
        return { input: data };
      },"entity-id":_vm.documentId,"query":_vm.QUERY,"query-variables":function () { return ({ documentId: _vm.documentId }); },"query-update":function (data) { return data.list.items[0]; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }