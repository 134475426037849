<template>
  <span>
    <AbstractModalForm
      :key="calcKey"
      :label="deliveryIntent === 'DELIVER_BY_MAIL' ? 'Wysłano pocztą' : 'Złożono osobiście'"
      :form-specification="formSpecification"
      :mutation="mutation"
      :mutation-error-mapper="(data) => (data.data.document.errors ? data.data.document.errors : [])"
      :mutation-variables="
        (data) => {
          data.documentId = document.id;
          return { input: data };
        }
      "
      :entity-data="document.envelope"
      @done="
        $emit('done');
        showModal = false;
      "
      ><template v-slot:activator="{ openDialog }">
        <v-btn @click="openDialog" :disabled="disabled" small fab color="secondary"><v-icon>mdi-email</v-icon></v-btn>
      </template>
    </AbstractModalForm>
  </span>
</template>
<script>
import { VFileInput, VTextField } from 'vuetify/lib';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import DateField from '@/components/FormFields/DateField.vue';
import MUTATION_BY_HAND from '@/graphql/envelope/mutation/DocumentDeliveredByHandMutation.gql';
import MUTATION_BY_MAIL from '@/graphql/envelope/mutation/DocumentDeliveredByMailMutation.gql';
import { isRequiredValidator } from '@/helpers/validators';

export default {
  name: 'SetShipmentForm',
  components: { AbstractModalForm },
  props: {
    document: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    calcKey() {
      return this.document.deliveryIntent + JSON.stringify(this.document.envelope);
    },
    deliveryIntent() {
      if (this.document.envelope) {
        return this.document.envelope.deliveryMethod;
      }

      return this.document.deliveryIntent;
    },
    formSpecification() {
      if (this.deliveryIntent === 'DELIVER_BY_MAIL') {
        return this.formSpecificationByMail;
      }
      return this.formSpecificationByHand;
    },
    mutation() {
      if (this.deliveryIntent === 'DELIVER_BY_MAIL') {
        return this.MUTATION_BY_MAIL;
      }
      return this.MUTATION_BY_HAND;
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION_BY_MAIL,
      MUTATION_BY_HAND,
      formSpecificationByMail: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'trackingNumber' },
                props: { label: 'Numer nadania' },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: DateField,
                initialData: () => new Date().toISOString(),
                model: { out: 'sentAt', in: 'actionDate' },
                props: { label: 'Data nadania' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
      formSpecificationByHand: {
        fields: [
          {
            columns: [
              // trackingNumber: String = null
              // documentIds: [ID!]!
              {
                cols: { md: 12 },
                type: VFileInput,
                model: { out: 'file' },
                props: { label: 'Potwierdzenie złożenia' },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: DateField,
                initialData: () => new Date().toISOString(),
                model: { out: 'deliveredAt' },
                props: { label: 'Data złożenia' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
