<template>
  <SetRelatedDocumentsForm
    :proceeding-id="proceedingId"
    :entity-id="taskId"
    :query="query"
    :query-variables="() => ({ taskId })"
    :query-update="(data) => data.list.items[0]"
    :mutation="mutation"
    :mutation-error-mapper="({ data: { task } }) => (task.hasOwnProperty('errors') ? task.errors : [])"
    :mutation-variables="
      (data, entityId) => {
        data.taskId = entityId;
        return { input: data };
      }
    "
    @done="$emit('done')"
  />
</template>
<script>
import SET_RELATED_TASKS_MUTATION from '@/graphql/task/mutation/SetTaskRelatedDocumentsMutation.gql';
import SetRelatedDocumentsForm from '@/components/Common/Form/SetRelatedDocumentsForm.vue';
import TASK_DETAILS_QUERY from '@/graphql/task/query/TaskDetailsQuery.gql';

export default {
  name: 'SetTaskRelatedDocumentsForm',
  components: { SetRelatedDocumentsForm },
  data() {
    return {
      mutation: SET_RELATED_TASKS_MUTATION,
      query: TASK_DETAILS_QUERY,
    };
  },
  props: {
    proceedingId: { type: String, required: true },
    taskId: { type: String, required: true },
  },
};
</script>
